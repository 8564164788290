import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  textOnly = this.route.snapshot.queryParamMap.get('textOnly') === 'true';
  sectionTitle: string;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.sectionTitle = 'Policy Privacy';
    if (this.textOnly) {
      document.querySelector('app-header').remove();
      document.querySelector('.navigation').remove();
      document.querySelector('.footer').remove();
    }
  }

}
