<app-header [sectionTitle]="sectionTitle"></app-header>
<div class="container-grid article sides-padding">
  <div class="share">
    <p><strong>Share</strong></p>
    <ul>
      <li>
        <a href="#"><i class="fab fa-facebook"></i></a>
      </li>
      <li>
        <a href="#"><i class="fab fa-twitter"></i></a>
      </li>
    </ul>
  </div>
  <div class="content">
    <h2>H2 Seeya Title</h2>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque libero accusamus non nulla qui labore autem soluta provident adipisci nesciunt reprehenderit error repellendus, omnis veniam vero tenetur. Sequi, minima dolorum.Accusantium aspernatur optio velit, aliquam laborum nostrum? Praesentium dolor doloribus quae eaque porro reprehenderit adipisci, eligendi molestiae neque debitis ipsam pariatur ipsum iusto, quasi aut dolore! Quo nisi atque libero.</p>
    <h3>H3 Seeya Title</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque libero accusamus non nulla qui labore autem soluta provident adipisci nesciunt reprehenderit error repellendus, omnis veniam vero tenetur. Sequi, minima dolorum.Accusantium aspernatur optio velit, aliquam laborum nostrum? Praesentium dolor doloribus quae eaque porro reprehenderit adipisci, eligendi molestiae neque debitis ipsam pariatur ipsum iusto, quasi aut dolore! Quo nisi atque libero.</p>
    <h4>H4 Seeya Title</h4>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque libero accusamus non nulla qui labore autem soluta provident adipisci nesciunt reprehenderit error repellendus, omnis veniam vero tenetur. Sequi, minima dolorum.Accusantium aspernatur optio velit, aliquam laborum nostrum? Praesentium dolor doloribus quae eaque porro reprehenderit adipisci, eligendi molestiae neque debitis ipsam pariatur ipsum iusto, quasi aut dolore! Quo nisi atque libero.</p>
    <h4>H4 Seeya Title</h4>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque libero accusamus non nulla qui labore autem soluta provident adipisci nesciunt reprehenderit error repellendus, omnis veniam vero tenetur. Sequi, minima dolorum.Accusantium aspernatur optio velit, aliquam laborum nostrum? Praesentium dolor doloribus quae eaque porro reprehenderit adipisci, eligendi molestiae neque debitis ipsam pariatur ipsum iusto, quasi aut dolore! Quo nisi atque libero.</p>
  </div>
</div>