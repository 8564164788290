<div class="grid-container">
  <div class="navigation">
    <nav class="navigation__container sides-padding fade-in-navigation">
      <div class="logo">
        <img routerLink="/" src="/assets/img/logo.svg" alt="seeya logo">
      </div>
      <div class="menu desktop">
        <ul>
          <li><a routerLink="/" routerLinkActive="active">Home</a></li>
          <li><a routerLink="/careers" routerLinkActive="active">Careers</a></li>
          <li><a routerLink="/contactus" routerLinkActive="active">Contact Us</a></li>
        </ul>
        <button><a [attr.href]="businessLogin">Business Login</a></button>
      </div>
      <div class="menu mobile">
        <button><a (click)="toogleMobileMenu = !toogleMobileMenu"><i class="fas fa-bars"></i></a></button>
        <div *ngIf="toogleMobileMenu" class="items-container">
          <div>
            <div class="main sides-padding">
              <img src="/assets/img/logo.svg" alt="seeya logo">
              <button><a (click)="toogleMobileMenu = !toogleMobileMenu"><i class="fas fa-bars"></i></a></button>
            </div>
          </div>
          <ul>
            <li><a routerLink="/" routerLinkActive="active">Home</a></li>
            <li><a routerLink="/careers" routerLinkActive="active">Careers</a></li>
            <li><a routerLink="/contactus" routerLinkActive="active">Contact Us</a></li>
          </ul>
          <button><a [attr.href]="businessLogin">Business Login</a></button>
        </div>
        <div *ngIf="toogleMobileMenu" (click)="toogleMobileMenu = !toogleMobileMenu" class="backdrop"></div>
      </div>
    </nav>    
  </div>
  <div class="content">
    <div class="header">
      <div class="hero-banner sides-padding">
        <div class="container-content">
          <div class="picture fade-in-image">
            <img src="/assets/img/404/404picture@2x.png" alt="404error">
          </div>
          <div class="txt fade-in-caption">
            <h2>404 - Page not found</h2>
            <p>Looks like this page doesn’t exist or can’t be found. </p>
            <button class="normal"><a routerLink="/">Go Back Home</a></button>
          </div>
        </div>
        <div class="wrapper-svg">
          <svg
            class="waves"
            fill="#ffa400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none"
          >
            <path
              style="opacity: 0.15"
              d="M 0 14 s 88.64 3.48 300 36 c 260 40 514 27 703 -10 l 12 28 l 3 36 h -1018 z"
            ></path>
            <path
              style="opacity: 0.3"
              d="M 0 45 s 271 45.13 500 32 c 157 -9 330 -47 515 -63 v 86 h -1015 z"
            ></path>
            <path
              d="M 0 58 s 188.29 32 508 32 c 290 0 494 -35 494 -35 v 45 h -1002 z"
            ></path>
          </svg>
        </div>
      </div>
      <!--Waves end-->
    </div>
    <!-- features description -->
    <section class="all-features">
      <div class="footer">
        <div class="footer__container">
          <div class="logo">
           
            <p>SeeYa! Mobile 2021. All Right Reserved</p>
          </div>
          <div class="menu">
            <div class="social-media">
              <ul>
                <li>
                  <a routerLink="/policy">Privacy Policy&nbsp; | </a>
                </li>
                <li>
                  <a routerLink="/terms">Terms of Use</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>     
  </div>
</div>