import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  textOnly = this.route.snapshot.queryParamMap.get('textOnly') === 'true';
  sectionTitle: string;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.sectionTitle = 'Terms of Use';
    if (this.textOnly) {
      document.querySelector('app-header').remove();
      document.querySelector('.navigation').remove();
      document.querySelector('.footer').remove();
    }
  }

}
