<!-- hero section -->
<section class="hero-banner svg-wrapper">
  <div class="hero-banner__title sides-padding grid-container">
    <div class="titles">
      <h1>Notify your friends and family to your arrival.</h1>
      <h6>
        Smart features designed to protect and connect the people who matter
        most.
      </h6>
      <div class="stores-btn">
        <div class="apps-store">
          <a link href="https://www.apple.com"><img src="/assets/img/appstore@2x.png" alt="seeya store" /></a>
        </div>
        <div class="play-store">
          <a link href="https://www.google.com"><img src="/assets/img/playstore@2x.png" alt="seeya store" /></a>
        </div>
      </div>
    </div>
    <div class="picture text-center">
      <img src="/assets/img/hero-picture@2x.png" alt="seeya" />
    </div>
  </div>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1600 846.458"
    preserveAspectRatio="none"
  >
    <path
      id="Intersection_10"
      data-name="Intersection 10"
      d="M-3511.459,1525.949V857.875h1600v543.506l-1061.975,284.555a536.911,536.911,0,0,1-139.064,18.4C-3265.966,1704.34-3410.963,1637.939-3511.459,1525.949Z"
      transform="translate(3511.459 -857.875)"
    />
  </svg>
</section>
<!-- main futures -->
<section class="features-glance sides-padding m-t-10">
  <div class="title text-center">
    <span class="label yellow"> Features </span>
    <h2>SeeYa! Mobile helps get to your destination and get your orders fast.</h2>
    <p>
      The ultimate curbside expereince is  <br />
      in the palm of your hands.
    </p>
  </div>
  <div class="features-container m-t-5">
    <div class="feature-item one">
      <div>
        <img
          src="/assets/img/landing/collaboration.svg"
          alt="seeya landing page"
        />
      </div>
      <h3>Share Your Destination</h3>
      <p>
        Easily and quickly share your departure and arrival times to friends and family members. Let them know you made it home or to work safely. 
      </p>
    </div>
    <div class="feature-item two">
      <div>
        <img
          src="/assets/img/landing/chat.svg"
          alt="seeya landing page"
        />
      </div>
      <h3>No More Waiting</h3>
      <p>
        Don't spend time waiting for pick up, or in waiting rooms. With SeeYa! Mobile, people and businesses know exactly when you've arrived so they can prepare.
      </p>
    </div>
    <div class="feature-item three">
      <div>
        <img
          src="/assets/img/landing/tracker.svg"
          alt="seeya landing page"
        />
      </div>
      <h3>Social Distance</h3>
      <p>
        With SeeYa Mobile! you can easily social distance and get to the places you need with out touch or interaction that puts you at risk.
      </p>
    </div>
  </div>
  <div class="cta text-center m-t-5">
    <button class="arrow-btn">
      <a class="arrow text-bold">Find out more</a>
    </button>
  </div>
</section>
