import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-mini-site',
  templateUrl: './mini-site.component.html',
  styleUrls: ['./mini-site.component.scss']
})
export class MiniSiteComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private renderer: Renderer2
    ) { }

  ngOnInit() {
    this.renderer.addClass(document.body, 'bg-yellow');
  }

  getNews() {
    this.router.navigate(['/news-and-media/seeya-piloting-dfw']);
  }

  getPrivacy() {
    this.router.navigate(['/policy']);
  }

  getTerms() {
    this.router.navigate(['/terms']);
  }

  ngOnDestroy(){
    this.renderer.removeClass(document.body, 'bg-yellow');
  }

}
