<app-header [sectionTitle]="sectionTitle"></app-header>
<div class="container-grid news sides-padding">
  <div class="col2">
    <div class="container">
      <div class="titles">
        <h1>End User License Agreement</h1>
        <div class="line"></div>
      </div>
      <p>
        THIS AGREEMENT (or "EULA") is a legal agreement between
        <strong>Overall Parts Solutions </strong>(hereinafter also referred to
        as &ldquo;OPS&rdquo;), a Texas Limited Liability Company with an address
        of 1450 Post &amp; Paddock, Grand Prairie, TX 75050, and
        <strong style="text-transform: capitalize;"> {{ name }} </strong>("Licensee&rdquo;).&nbsp;
      </p>
      <p>
        WHEREAS, Overall Parts Solutions has developed software that allows for
        controlled, contactless, visitor management, and tracking (the
        &ldquo;Software&rdquo;).
      </p>
      <p>
        WHEREAS, the Licensee desires to install or access the Software via a
        network, and use the Software subject to the terms of this End User
        License Agreement.
      </p>
      <p>
        Now, therefore, intending to be bound by the terms of this agreement,
        Overall Parts Solutions and Licensee agree to the following:
      </p>
      <p><strong>1. LICENSE GRANTS</strong></p>
      <p>
        The licenses granted in this Section 1 are subject to the terms and
        conditions set forth in this EULA:
      </p>
      <p>
        (a) You may install, access via a network, or use the Software on
        multiple computers or computing device.&nbsp; However, the Software may
        not be shared, installed, or used concurrently with different business
        locations, and your access credentials may not be shared with anyone.
        For clarity, each business location, and/or individual is required to
        sign a separate EULA, and that agreement cannot be shared or
        transferred.
      </p>
      <p>
        &nbsp;(b) You agree that Overall Parts Solutions may audit your use of
        the Software for compliance with these terms at any time, upon
        reasonable notice. In the event that such audit reveals any use of the
        Software by you other than in full compliance with the terms of this
        Agreement, you shall reimburse Overall Parts Solutions for all
        reasonable expenses related to such audit in addition to any other
        liabilities you may incur as a result of such non-compliance.
      </p>
      <p>
        &nbsp;(c) Your license rights under this EULA are non-exclusive.&nbsp;
      </p>
      <p><strong>2. LICENSE RESTRICTIONS</strong></p>
      <p>
        (a) Other than as set forth in Section 2, you may not make or distribute
        copies of the Software, download or otherwise transfer web-based
        versions of the Software, electronically transfer the Software from one
        computer to another or over a network, or access your account of the
        Software for use with a business entity other than the one licensing the
        Software if not for personal use.
      </p>
      <p>
        (b) You may not alter, merge, modify, adapt, or translate the Software,
        or decompile, reverse engineer, disassemble, or otherwise reduce the
        Software to a human-perceivable form.
      </p>
      <p>
        (c) Unless otherwise provided herein, you may not rent, lease, or
        sublicense the Software.
      </p>
      <p>
        (d) You may not transfer any of your rights under this EULA, however,
        this section does not include transfer to a new device.&nbsp; For
        clarity, if a user upgrades their mobile phone and downloads the latest
        version of the software, or accesses the latest version of the software,
        it would not be considered a transfer.&nbsp; A transfer is providing
        access to an non-authorized business or user, i.e., another person or
        entity that has not agreed to this EULA and any other corresponding
        agreements with OPS.
      </p>
      <p>
        (e) Unless otherwise provided herein, you may not modify the Software or
        create derivative works based upon the Software.
      </p>
      <p>
        (f) You may not export the Software into any country prohibited by the
        United States Export Administration Act and the regulations thereunder.
      </p>
      <p>
        (g) You shall not use the Software to develop any product having the
        same primary function as the Software.
      </p>
      <p>
        (h) In the event that you fail to comply with this EULA, Overall Parts
        Solutions may terminate the license and you must destroy all copies of
        the Software (with all other rights of both parties and all other
        provisions of this EULA surviving any such termination).
      </p>
      <p><strong>3. OWNERSHIP</strong></p>
      <p>
        The foregoing license gives you limited license to use the Software.
        Overall Parts Solutions retains all right, title and interest it is
        allowed, including all copyright and intellectual property rights, in
        and to, the Software (as an independent work and as an underlying work
        serving as a basis for any application you may develop), and all copies
        thereof. All rights not specifically granted in this EULA, including
        Federal and International Copyrights, are reserved by Overall Parts
        Solutions, or to the licensed rights holders.
      </p>
      <p>
        Additionally, data that is entered into, or tracked within the Software
        is the property of the User or Overall Parts Solutions, who may retain
        all right, title, and interest it is allowed in the data.&nbsp; All data
        gathered or tracked is maintained within the terms of the 
        <a href="/policy{{textOnly ? '?textOnly=true' : ''}}">privacy policy</a> and <a href="/terms{{textOnly ? '?textOnly=true' : ''}}">Terms of Service</a>.&nbsp;
      </p>
      <p><strong>4. LIMITED WARRANTY AND DISCLAIMER</strong></p>
      <p>
        (a) Overall Parts Solutions warrants that, for a period of ninety (90)
        days from the date of delivery (as evidenced by a copy of your receipt):
        (i) when used with a recommended hardware configuration, the Software
        will perform in substantial conformance with the documentation supplied
        with the Software; and (ii) the physical media (if any) on which the
        Software is furnished will be free from defects in materials and
        workmanship under normal use.
      </p>
      <p>
        (b) Overall Parts Solutions provides no remedies or warranties, whether
        express or implied, for the software. The software is provided
        &ldquo;as-is&rdquo;.
      </p>
      <p>
        (c) Except as set forth in the foregoing Limited Warranty with respect
        to the software, Overall Parts Solutions disclaims all other warranties
        and representations, whether express, implied, or otherwise, including
        the warranties of merchantability or fitness for a particular purpose.
        Also, there is no warranty of non-infringement and title or quiet
        enjoyment. Overall Parts Solutions does not warrant that the software is
        error-free or will operate without interruption. No rights or remedies
        referred to in article 2A of the UCC will be conferred on you unless
        expressly granted herein.
      </p>
      <p>
        <strong
          >(d) If applicable law requires any warranties with respect to the
          software, all such warranties are limited in duration to ninety (90)
          days from the date of delivery.
        </strong>
      </p>
      <p>
        (e)No oral or written information or advice given by Overall Parts
        Solutions, its agents, or employees shall create a warranty or in any
        way increase the scope of any warranty provided herein.
      </p>
      <p>
        (f) (USA only) Some states do not allow the exclusion of implied
        warranties, so the above exclusions may not apply to you. This warranty
        gives you specific legal rights, and you may also have other legal
        rights that vary from state.
      </p>
      <p>
        (h) Additional warranties, or exclusion of warranties may also be
        covered in the <a href="/policy{{textOnly ? '?textOnly=true' : ''}}">Privacy Policy</a> and <a href="/terms{{textOnly ? '?textOnly=true' : ''}}">Terms of Service</a>, however, in no event shall Overall Parts Solutions, or a
        business utilizing the Services be liable use of the Software that
        results in a failure to adhere to safe vehicle operating procedures or
        failure operate a vehicle in a safe manner, and a user is responsible
        for the Data that they agree to provide during their use of the Software
        as noted in the Privacy Policy and Terms of Service.&nbsp;
      </p>
      <p><strong>5. EXCLUSIVE REMEDY</strong></p>
      <p>
        Your exclusive remedy under the preceding is to return the Software to
        Overall Parts Solutions. Provided that any non-compliance with the above
        warranty is reported in writing to Overall Parts Solutions no more than
        ninety (90) days following delivery to you, Overall Parts Solutions will
        use reasonable commercial efforts to supply you with a replacement copy
        of the Software that substantially conforms to the documentation,
        provide a replacement for defective media, or refund to you your
        purchase price for the Software, at its option. Overall Parts Solutions
        shall have no responsibility if the Software has been altered in any
        way, if the media or link has been damaged by misuse, accident, abuse,
        modification, or misapplication, or if the failure arises out of use of
        the Software with other than a recommended hardware configuration. Any
        such misuse, accident, abuse, modification, or misapplication of the
        Software will void the warranty above. This remedy is the sole and
        exclusive remedy available to you for breach of express or implied
        warranties with respect to the software and related documentation.
      </p>
      <p><strong>6. LIMITATION OF LIABILITY</strong></p>
      <p>
        (a) Overall Parts Solutions shall not be liable to you or any third
        party for any indirect, special, incidental, punitive, cover or
        consequential damages (including, but not limited to, damages for the
        inability to use equipment or access data, loss of business, loss of
        profits, business interruption or the like), arising out of the use of,
        or inability to use, the software and based on any theory of liability
        including breach of contract, breach of warranty, tort (including
        negligence), product liability or otherwise, even if overall parts
        solutions or its representatives have been advised of the possibility of
        such damages and even if a remedy set forth herein is found to have
        failed of its essential purpose.
      </p>
      <p>
        (b) Overall Parts Solutions' total liability to you for actual damages
        for any cause, whatsoever will be limited to the greater of $500 or the
        amount paid by you for the software that caused such damage.
      </p>
      <p>
        (c) (USA only) Some states do not allow the limitation or exclusion of
        liability for incidental or consequential damages, so the above
        limitation or exclusion may not apply to you, and you may also have
        other legal rights that vary from state to state.
      </p>
      <p>
        (d) The foregoing limitations on liability are intended to apply to the
        warranties and disclaimers above and all other aspects of this EULA.
      </p>
      <p><strong>7. BASIS OF BARGAIN</strong></p>
      <p>
        The Limited Warranty and Disclaimer, Exclusive Remedies and Limited
        Liability set forth above are fundamental elements of the basis of the
        agreement between Overall Parts Solutions and you. Overall Parts
        Solutions would not be able to provide the Software on an economic basis
        without such limitations.
      </p>
      <p><strong>8. ADDITIONAL LICENSE NOTICES</strong></p>
      <p>
        Overall Parts Solutions may incorporate additional licensed software,
        libraries, or code as part of its software that are covered by their own
        licenses and these licenses do not cover the Overall Parts Solutions
        product, software, or any independently created works.&nbsp;
      </p>
      <p>
        Where these additional licensed software, libraries, or code include
        various aspects of term language or restrictions as to the licenses
        under which the additional licensed software, libraries, or code may be
        utilized or distributed, those additional licensed software, libraries,
        or code are covered by their particular licenses.&nbsp; With the
        exception that if allowed, any terms or conditions not expressly covered
        by the licenses of the additional licensed software, libraries, or code
        and are covered herein by this Agreement then the terms of the Agreement
        will govern a disagreement.&nbsp;
      </p>
      <p>
        These additional licenses may include notice provisions as part of their
        usage, or distribution, if any, of these additional licensed software,
        libraries, or code.&nbsp; While Overall Parts Solutions makes efforts to
        ensure that these notices are provided in the proper context within the
        software and system, out of abundance of caution we are providing copies
        of all of the notices at the linked location below.&nbsp; For clarity,
        each additional licensed software, libraries, or code is used as is
        without any modifications, and Overall Parts Solutions will provide
        copies of these additional licenses, software, libraires, or code upon
        request, and a reasonable fee to cover the cost of transmitting said
        items in the request.&nbsp; These requests will not extend to any
        software, libraries, or code developed independently by Overall Parts
        Solutions.&nbsp; Finally, this additional licensed software, libraries,
        or code is accessed through a network connection, and it is believed
        that no portion of the additional licensed software, libraries, or code
        are transferred to a user computer or device and this additional
        licensed software, libraries, or code is not transferred or transmitted
        to the user, if Overall Parts Solutions becomes aware of any transfer
        all reasonable efforts will be made to include the appropriate notices
        with the transfer. &nbsp;These notices are examples, or information that
        are included with the licensed software, libraries, or code, Overall
        Parts Solutions does not claim or provide that the notices below are
        complete notices for any of the licensed software, libraries, or
        code.&nbsp; Overall Parts Solutions can provide a list of the notices
        for each of the licensed software, libraries, or code upon written
        request, and a reasonable processing fee.
      </p>
      <p><strong>9. General</strong></p>
      <p>
        This EULA shall be governed by the internal laws of the State of Texas,
        without giving effect to principles of conflict of laws. You hereby
        consent to the exclusive jurisdiction and venue of the state courts
        sitting in Dallas County, Texas or the federal courts in the Northern
        District of Texas to resolve any disputes arising under this EULA. In
        each case this EULA shall be construed and enforced without regard to
        the United Nations Convention on the International Sale of Goods.
      </p>
      <p>
        This EULA contains the complete agreement between the parties with
        respect to the subject matter hereof, and supersedes all prior or
        contemporaneous agreements or understandings, whether oral or written.
        You agree that any varying or additional terms contained in any purchase
        order or other written notification or document issued by you in
        relation to the Software licensed hereunder shall be of no effect. The
        failure or delay of Overall Parts Solutions to exercise any of its
        rights under this EULA or upon any breach of this EULA shall not be
        deemed a waiver of those rights or of the breach.
      </p>
      <p>
        No Overall Parts Solutions agent or employee is authorized to make any
        amendment to this EULA unless such amendment is in writing and signed by
        a duly authorized representative of Overall Parts Solutions.
      </p>
      <p>
        If any provision of this EULA shall be held by a court of competent
        jurisdiction to be contrary to law, that provision will be enforced to
        the maximum extent permissible, and the remaining provisions of this
        EULA will remain in full force and effect.
      </p>
      <p>
        Overall Parts Solutions and other trademarks contained in the Software
        are trademarks or registered trademarks of Overall Parts Solutions, Inc.
        in the United States and/or other countries.
      </p>
    </div>
  </div>
</div>
