import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  sectionTitle: string;

  constructor() { }

  ngOnInit() {
    this.sectionTitle = 'Help & Support';
  }

}
