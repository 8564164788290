import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment as environmentConfig } from '@appFunctions/env.generated';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent implements OnInit, OnDestroy {

  toogleMobileMenu = false;
  businessLogin = environmentConfig.siteOrigin;

  constructor(private router: Router,
              private renderer: Renderer2) { }


  ngOnInit() {
    this.renderer.addClass(document.body, 'bg-yellow');
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      this.toogleMobileMenu = false;
  });
  }

  toogleMenu() {
    this.toogleMobileMenu = true;
  }

  ngOnDestroy(){
    this.renderer.removeClass(document.body, 'bg-yellow');
  }

}
