<div class="container">
    <div class="download-cta">
        <h3>SeeYa! Mobile </h3>
        <p>allows you to share your location and destinations with friends and family!</p>
        <button class="button square m-b-1">Download Now</button>
    </div>
    <div class="task-card">
        <div class="header">Someone is on their way to…</div>
        <div class="body-card">
            <div class="col-left">
                <h3 class="ellipsys">{{task.name}}</h3>
                <p>To see more details download SeeYa! Mobile now!</p>
            </div>
            <div class="col-right">
                
                    <div class="picture" [ngStyle]="{'background-image': 'url(' + showPicture() + ')'}" ></div>
                
            </div>
        </div>
    </div>
</div>
