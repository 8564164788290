<nav class="navigation__container sides-padding" [ngClass]="isLanding === true ? '' : 'nolanding'">
  <div class="logo">
    <img routerLink="/" *ngIf="isLanding" src="/assets/img/logo.svg" alt="seeya logo">
    <img routerLink="/" *ngIf="!isLanding" src="/assets/img/logo-white.svg" alt="seeya logo">
  </div>
  <div class="menu desktop">
    <ul>
      <li><a routerLink="/" routerLinkActive="active">Home</a></li>
      <li><a routerLink="/careers" routerLinkActive="active">Careers</a></li>
      <li><a routerLink="/contactus" routerLinkActive="active">Contact Us</a></li>
    </ul>
    <button><a [attr.href]="businessLogin">Business Login</a></button>
  </div>
  <div class="menu mobile">
    <button><a (click)="toogleMobileMenu = !toogleMobileMenu"><i class="fas fa-bars"></i></a></button>
    <div *ngIf="toogleMobileMenu" class="items-container">
      <div>
        <div class="main sides-padding">
          <img src="/assets/img/logo.svg" alt="seeya logo">
          <button><a (click)="toogleMobileMenu = !toogleMobileMenu"><i class="fas fa-bars"></i></a></button>
        </div>
      </div>
      <ul>
        <li><a routerLink="/" routerLinkActive="active">Home</a></li>
        <li><a routerLink="/careers" routerLinkActive="active">Careers</a></li>
        <li><a routerLink="/contactus" routerLinkActive="active">Contact Us</a></li>
      </ul>
      <button><a [attr.href]="businessLogin">Business Login</a></button>
    </div>
    <div *ngIf="toogleMobileMenu" (click)="toogleMobileMenu = !toogleMobileMenu" class="backdrop"></div>
  </div>
</nav>
