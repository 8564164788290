<app-header [sectionTitle]="sectionTitle"></app-header>
<div class="container-grid news sides-padding">
  <div class="col2">
    <div class="container">
      <div class="titles">
        <h1>OPS Privacy Policy</h1>
        <div class="line"></div>
      </div>
      <p>
        <strong>OVERALL PARTS SOLUTIONS PRIVACY STATEMENT (California
          Compliant)</strong>
      </p>
      <p>
        <em>Effective Date: January 1, 2020<br>
          Last Updated: September 13, 2021</em><br>
        <br>
      </p>
      <p><strong>PRIVACY IS A TOP PRIORITY FOR OPS</strong></p>
      <p><strong>WHAT DOES THIS POLICY APPLY TO?</strong></p>
      <p>
        The following Privacy Policy describes the practices and measures, as
        well as marketing and information gathering efforts, undertaken by
        Overall Parts Solutions, dba OPS (“OPS” or
        “we”), for its mobile applications, websites, and services.
        This includes SeeYa! Mobile, Seeya! For Business, SeeYa! Valet,
        SeeYaMobile.com, and other features of (the “Platform” or
        “Platforms”).<br>
        <br>
        <strong>Your use of the Platform signifies your agreement to be bound by our
          Privacy Policy. If you do not agree to the terms of the Privacy
          Policy, please do not access or use the Platform.</strong>
      </p>
      <p><strong>WHAT THIS PRIVACY POLICY COVERS</strong></p>
      <p>
        This privacy policy (the "Privacy Policy") is designed to help you
        understand what information we collect about you, how we collect, use,
        share, store and secure any information you may provide to us on our
        Platforms when you use our products or services, or otherwise interact
        with us. This includes registering for and receiving any of our print
        publications, digital e-mails, and newsletters; accessing our website;
        enrolling in any of our subscription products; attending our events,
        conferences, and webinars; downloading sponsored research and content;
        viewing our videos, and other content; and using our mobile and web
        applications and social media channels (collectively, our
        “Products”), unless a different policy is displayed. Unless
        otherwise noted, our Products are provided by OPS in the United States.
      </p>
      <p>
        This policy also explains your choices about how you can access and
        control your information. Your choices include how you can modify your
        preferences, object to certain uses of your information, and how you can
        update certain information about you.
      </p>
      <p>
        <em>This PRIVACY POLICY complies with the requirements of the California
          Consumer Privacy Act and applies to visitors, users, and others who
          reside in the State of California (“consumers” or
          “you”). We adopt this notice to comply with the California
          Consumer Privacy Act of 2018 (“CCPA”) and other California
          privacy laws. Any terms defined in the CCPA have the same meaning when
          used in this notice.</em><br>
        <br>
      </p>
      <p><strong>PERSONAL INFORMATION WE COLLECT</strong></p>
      <p>
        We collect information that identifies, relates to, describes,
        references, is capable of being associated with, or could reasonably be
        linked, directly or indirectly, with a particular consumer or device
        (“personal information”). Specifically, we have collected
        the following categories of personal information about consumers within
        the last twelve (12) months:
      </p>
      <div class="table-container table-responsive-md">
        <table class="table table-hover" width="100%">
          <tbody>
            <tr>
              <td width="27%">
                <p><strong>Category</strong></p>
              </td>
              <td width="60%">
                <p><strong>Examples</strong></p>
              </td>
              <td width="11%">
                <p><strong>Collected</strong></p>
              </td>
            </tr>
            <tr>
              <td width="27%">
                <p>A. Identifiers.</p>
              </td>
              <td width="60%">
                <p>
                  A real name, alias, postal address, unique personal identifier,
                  online identifier, Internet Protocol address, email address,
                  account name, driver's license number, passport number, or other
                  similar identifiers.
                </p>
              </td>
              <td width="11%">
                <p>YES</p>
              </td>
            </tr>
            <tr>
              <td width="27%">
                <p>
                  B. Personal information categories listed in the California
                  Customer Records statute (Cal. Civ. Code § 1798.80(e)).
                </p>
              </td>
              <td width="60%">
                <p>
                  A name, signature, physical characteristics or description,
                  address, telephone number, passport number, driver's license or
                  state identification card number, education, employment,
                  employment history or titles, bank account number, credit card
                  number, debit card number, or any other financial information.
                  Some personal information included in this category may overlap
                  with other categories.
                </p>
              </td>
              <td width="11%">
                <p>YES</p>
              </td>
            </tr>
            <tr>
              <td width="27%">
                <p>
                  C. Protected classification characteristics under California or
                  federal law.
                </p>
              </td>
              <td width="60%">
                <p>
                  Age (40 years or older), race, color, ancestry, national origin,
                  citizenship, religion or creed, marital status, medical
                  condition, physical or mental disability, sex (including gender,
                  gender identity, gender expression, pregnancy or childbirth and
                  related medical conditions), sexual orientation, veteran or
                  military status, genetic information (including familial genetic
                  information).
                </p>
              </td>
              <td width="11%">
                <p>No</p>
              </td>
            </tr>
            <tr>
              <td width="27%">
                <p>D. Commercial information.</p>
              </td>
              <td width="60%">
                <p>
                  Records of personal property, products or services purchased,
                  obtained, or considered, or other purchasing or consuming
                  histories or tendencies.
                </p>
              </td>
              <td width="11%">
                <p>YES</p>
              </td>
            </tr>
            <tr>
              <td width="27%">
                <p>E. Biometric information.</p>
              </td>
              <td width="60%">
                <p>
                  Genetic, physiological, behavioral, and biological
                  characteristics, or activity patterns used to extract a template
                  or other identifier or identifying information, such as,
                  fingerprints, faceprints, and voiceprints, iris or retina scans,
                  keystroke, gait, or other physical patterns, and sleep, health,
                  or exercise data.
                </p>
              </td>
              <td width="11%">
                <p>NO</p>
              </td>
            </tr>
            <tr>
              <td width="27%">
                <p>F. Internet or other similar network activity.</p>
              </td>
              <td width="60%">
                <p>
                  Browsing history, search history, information on a consumer's
                  interaction with or access to a website, application, or
                  advertisement, or social media activities.
                </p>
              </td>
              <td width="11%">
                <p>YES</p>
              </td>
            </tr>
            <tr>
              <td width="27%">
                <p>G. Geolocation data.</p>
              </td>
              <td width="60%">
                <p>Physical location or movements.</p>
              </td>
              <td width="11%">
                <p>YES</p>
              </td>
            </tr>
            <tr>
              <td width="27%">
                <p>H. Sensory data.</p>
              </td>
              <td width="60%">
                <p>
                  Audio, electronic, visual, thermal, olfactory, or similar
                  information.
                </p>
              </td>
              <td width="11%">
                <p>NO</p>
              </td>
            </tr>
            <tr>
              <td width="27%">
                <p>I. Professional or employment-related information.</p>
              </td>
              <td width="60%">
                <p>
                  Current or past job history or performance evaluations, titles,
                  business addresses, or similar information.
                </p>
              </td>
              <td width="11%">
                <p>YES</p>
              </td>
            </tr>
            <tr>
              <td width="27%">
                <p>
                  J. Non-public education information (per the Family Educational
                  Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part
                  99)).
                </p>
              </td>
              <td width="60%">
                <p>
                  Education records directly related to a student maintained by an
                  educational institution or party acting on its behalf, such as
                  grades, transcripts, class lists, student schedules, student
                  identification codes, student financial information, or student
                  disciplinary records.
                </p>
              </td>
              <td width="11%">
                <p>NO</p>
              </td>
            </tr>
            <tr>
              <td width="27%">
                <p>K. Inferences drawn from other personal information.</p>
              </td>
              <td width="60%">
                <p>
                  Profile reflecting a person's preferences, characteristics,
                  psychological trends, predispositions, behavior, attitudes,
                  intelligence, abilities, and aptitudes.
                </p>
              </td>
              <td width="11%">
                <p>YES</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <p>
        <br>
        <br>
      </p>
      <p>Personal information does not include:</p>
      <ul>
        <li>Publicly available information from government records.</li>
        <li>De-identified or aggregated consumer information.</li>
        <li>Information excluded from the CCPA's scope, like:</li>
        <ul>
          <li>
            health or medical information covered by the Health Insurance
            Portability and Accountability Act of 1996 (HIPAA) and the
            California Confidentiality of Medical Information Act (CMIA) or
            clinical trial data;
          </li>
          <li>
            personal information covered by certain sector-specific privacy
            laws, including the Fair Credit Reporting Act (FRCA), the
            Gramm-Leach-Bliley Act (GLBA) or California Financial Information
            Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.
          </li>
        </ul>
      </ul>
      <p><br></p>
      <p><strong>&nbsp;</strong></p>
      <p><strong>HOW WE COLLECT PERSONAL INFORMATION</strong></p>
      <p>
        We obtain the categories of personal information listed above from the
        following categories of sources:
      </p>
      <ul>
        <li>
          Directly from you or your agents. For example, from information
          provided by you when you register for an account, create, modify or
          set preferences for your online profile, visit our booth at an event,
          or sign up for or make purchases of products or services we offer.
        </li>
        <li>
          Indirectly from you or your agents. For example, through information
          we collect from you in the course of providing products or services to
          you.
        </li>
        <li>
          Directly and indirectly from your activity on our website
          (www.seeyamobile.com) or on your social media platforms. For example,
          from submissions through our website portal or website usage details
          collected automatically, or through the collection of first- or
          third-party cookies.
        </li>
        <li>
          From other users of our products.&nbsp;Other users of our Products may
          provide information about you when they submit content through the
          products, forward you an article, or invite you to use the products.
          Similarly, your employer may provide your contact information when
          they designate you as a recipient user of the products.
        </li>
        <li>
          From third-parties that interact with us in connection with the
          products we provide and the services we perform. For example,
          advertising and market research partners provide us with information
          about your interest in and engagement with our products and online
          ads; and global partners provide consulting, implementation, training,
          marketing, promotion, and other services around our products.
        </li>
      </ul>
      <p><strong>&nbsp;</strong></p>
      <p><strong>Information automatically collected</strong></p>
      <p>
        We may automatically collect certain information when you visit, use or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Services and other technical information. This information is
        primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes.
      </p>
      <p>The information we collect includes:</p>
      <ul>
        <li>
          <em>Log and Usage Data.</em> Log and usage data is service-related,
          diagnostic, usage and performance information our servers
          automatically collect when you access or use our Services and which we
          record in log files. Depending on how you interact with us, this log
          data may include your IP address, device information, browser type and
          settings and information about your activity in the Services (such as
          the date/time stamps associated with your usage, pages and files
          viewed, searches and other actions you take such as which features you
          use), device event information (such as system activity, error reports
          (sometimes called 'crash dumps') and hardware settings).
        </li>
        <li>
          <em>Device Data.</em> We collect device data such as information about
          your computer, phone, tablet or other device you use to access the
          Services. Depending on the device used, this device data may include
          information such as your IP address (or proxy server), device and
          application identification numbers, location, browser type, hardware
          model Internet service provider and/or mobile carrier, operating
          system and system configuration information.
        </li>
        <li>
          <em>Location Data.</em> We collect location data such as information
          about your device's location, which can be either precise or
          imprecise. How much information we collect depends on the type and
          settings of the device you use to access the Services. For example, we
          may use GPS and other technologies to collect geolocation data that
          tells us your current location (based on your IP address). You can opt
          out of allowing us to collect this information either by refusing
          access to the information or by disabling your Location setting on
          your device. Note however, if you choose to opt out, you may not be
          able to use certain aspects of the Services.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p><strong>Information collected through our App</strong></p>
      <p>If you use our App, we may also collect the following information:</p>
      <ul>
        <li>
          <em>Geolocation Information.</em>We may request access or permission
          to and track location-based information from your mobile device,
          either continuously or while you are using our App, to provide certain
          location-based services. If you wish to change our access or
          permissions, you may do so in your device's settings.
        </li>
        <li>
          <em>Mobile Device Access.</em>We may request access or permission to
          certain features from your mobile device, including your mobile
          device's camera, microphone, SMS messages, storage, and other
          features. If you wish to change our access or permissions, you may do
          so in your device's settings.
        </li>
        <li>
          <em>Mobile Device Data.&nbsp;</em>We automatically collect device
          information (such as your mobile device ID, model and manufacturer),
          operating system, version information and system configuration
          information, device and application identification numbers, browser
          type and version, hardware model Internet service provider and/or
          mobile carrier, and Internet Protocol (IP) address (or proxy server).
          If you are using our App, we may also collect information about the
          phone network associated with your mobile device, your mobile
          device’s operating system or platform, the type of mobile device
          you use, your mobile device’s unique device ID and information
          about the features of our App you accessed.
        </li>
        <li>
          <em>Push Notifications.&nbsp;</em>We may request to send you push
          notifications regarding your account or certain features of the App.
          If you wish to opt-out from receiving these types of communications,
          you may turn them off in your device's settings.
        </li>
      </ul>
      <p>
        This information is primarily needed to maintain the security and
        operation of our App, for troubleshooting and for our internal analytics
        and reporting purposes.
      </p>
      <p>&nbsp;</p>
      <p><strong>HOW LONG WE KEEP YOUR INFORMATION</strong></p>
      <p>
        How long we keep information we collect about you depends on the type of
        information we have collected about you. We will also retain your
        information as necessary to comply with our legal obligations, resolve
        disputes, and enforce our agreements. After such a time, we will either
        delete or anonymize your information or, if this is not possible (for
        example, because the information has been stored in backup archives),
        then we will securely store your information and isolate it from any
        further use until deletion is possible.
      </p>
      <p>
        <u>Your Account Informatio</u>n:&nbsp;We retain your account information
        for as long as your account is active and a reasonable period thereafter
        in case you decide to re-activate the Products. We also retain some of
        your information as necessary to comply with our legal obligations, to
        resolve disputes, to enforce our agreements, to support business
        operations, and to continue to develop and improve our Products. We also
        keep personal data as otherwise required by law.
      </p>
      <p>
        <u>Managed accounts</u>:&nbsp;If the Products are made available to you
        through an organization (e.g., your employer), we retain your
        information as long as required by your employer.
      </p>
      <p>
        <u>Marketing information</u>:&nbsp;If you have elected to receive
        marketing emails from us, we retain information about your marketing
        preferences for a reasonable period of time from the date you last
        expressed interest in our Products, such as when you last opened an
        email from us or ceased using your OPS account. We retain information
        derived from cookies and other tracking technologies for a reasonable
        period of time from the date such information was created.
      </p>
      <p><strong>&nbsp;</strong></p>
      <p><strong>Use of Personal Information</strong></p>
      <p>
        We may use or disclose the personal information we collect for one or
        more of the following business purposes:
      </p>
      <ul>
        <li>
          To provide you with information, products or services, email alerts,
          event registrations and other notices concerning our products or
          services that you request from us or that may be of interest to you;
          and to market, promote, and drive engagement with our products.
        </li>
        <li>
          To create, maintain, customize, and secure your account with us.
        </li>
        <li>
          To fill orders, perform necessary&nbsp;business&nbsp;functions - such
          as processing your requests, purchases, transactions, and payments and
          preventing transactional fraud, carry out our obligations, and enforce
          our rights arising from any contracts entered into between us.
        </li>
        <li>
          To help us better understand your experience and meet your needs.
        </li>
        <li>
          To improve our website functionality and help maintain the safety,
          security, and integrity of our website.
        </li>
        <li>
          To provide, support, personalize, and develop our website, products,
          and services.
        </li>
        <li>
          To help us refine our marketing strategies through research and
          analytics and by tracking consumer web and application activities via
          internal and third-party tagging and cookies.
        </li>
        <li>
          As necessary or appropriate to protect the rights, property or safety
          of you, us, or others.
        </li>
        <li>
          To respond to law enforcement requests; as required by applicable law,
          court order, or governmental regulations; and to protect our
          legitimate business interests and legal rights; protect the security
          or integrity of our products or services; to protect us, our
          customers, or the public from harm or illegal activities; or respond
          to an emergency which we believe in good faith requires us to disclose
          information to assist in preventing the death or serious bodily injury
          of any person.
        </li>
        <li>
          As described to you when collecting your personal information or as
          otherwise set forth in the CCPA.
        </li>
        <li>
          To evaluate or conduct a merger, divestiture, restructuring,
          reorganization, dissolution, or other sale or transfer of some or all
          of our assets, whether as a going concern or as part of bankruptcy,
          liquidation, or similar proceeding, in which personal information held
          by us is among the assets transferred.
        </li>
        <li>
          when you give us consent or we are allowed to do so under applicable
          laws.
        </li>
      </ul>
      <p>
        We will not collect additional categories of personal information or use
        the personal information we collected for materially different,
        unrelated, or incompatible purposes without providing you notice or
        receiving your consent.
      </p>
      <p><strong>&nbsp;</strong></p>
      <p>
        <strong>How Do We Handle Your Social or Single Source Logins?</strong>
      </p>
      <p>
        Our Services may offer you the ability to register and login using your
        third-party social media or other account credentials (like your
        Facebook or Google logins). Where you choose to do this, we will receive
        certain profile information about you from your social media or account
        provider. The profile information we receive may vary depending on the
        provider concerned, but will often include your name, email address,
        friends or contact list, profile picture or avatar, as well as other
        information you choose to make public on such social media or provider
        platforms.
      </p>
      <p>
        We will use the information we receive only for the purposes that are
        described in this privacy notice or that are otherwise made clear to you
        on the relevant Services. Please note that we do not control, and are
        not responsible for, other uses of your personal information by your
        third-party provider. We recommend that you review their privacy notice
        to understand how they collect, use and share your personal information,
        and how you can set your privacy preferences on their sites and apps.
      </p>
      <p>&nbsp;</p>
      <p><strong>Sharing Personal Information</strong></p>
      <p>
        We may disclose your personal information to a third party for a
        business purpose. When we disclose personal information for a business
        purpose, we enter a contract that describes the purpose and requires the
        recipient to both keep that personal information confidential and not
        use it for any purpose except performing the contract. We do not sell,
        and have not sold in the preceding 12 months, any information about you
        to third parties.
      </p>
      <p>
        We disclose your personal information for a business purpose to the
        following categories of third parties:
      </p>
      <ul>
        <li>
          Other OPS product users and owners of managed accounts you use to
          access our Platforms.
        </li>
        <li>Our affiliates.</li>
        <li>Service providers.</li>
        <li>Marketing partners.</li>
        <li>Business transfers.</li>
        <li>
          Third parties to whom you or your agents authorize us to disclose your
          personal information in connection with products or services we
          provide to you.
        </li>
      </ul>
      <p>
        In the preceding twelve (12) months, we have disclosed the following
        categories of personal information for a business purpose:
      </p>
      <p>
        <b>Category
        A:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Identifiers.<br>
        <b>Category
        B:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        California Customer Records personal information categories.<br>
        <b>Category
        C:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Protected classification characteristics under California or federal
        law.<br>
        <b>Category
        I:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Professional or employment-related information.
      </p>
      <p>
        <strong><em><u>&nbsp;</u></em></strong>
      </p>
      <p>
        <strong><em><u>Cookies, Tracking Tools, and Targeted Advertising</u></em></strong>
      </p>
      <p>
        When you visit our website or use our applications, we and unrelated
        parties place cookies on your computer to track and store activity on
        our website and applications. Tracking technologies used are beacons,
        tags, and scripts to collect and track information and to improve and
        analyze our products and services. You can instruct your browser to
        refuse all cookies or to indicate when a cookie is being sent, but if
        you do not accept cookies, you may not be able to use our website,
        products, or services.
      </p>
      <p><strong>Use of Cookies</strong></p>
      <p>
        This cookie policy applies to use of this site, and not use of cookies
        in connection with other of our websites and applications not directly
        accessible from another OPS website or application.
      </p>
      <p><strong>First Party</strong></p>
      <p>
        We generally place cookies on your machine to collect analytics data,
        remember language settings and login information, and perform other
        useful functions that provide a good user experience. They are
        necessary/essential cookies, without which our site and its applications
        would not work or function properly or provide you certain features and
        functionalities. They help to authenticate users and prevent fraudulent
        use of user accounts.
      </p>
      <p>
        When you visit our website or use our applications, the use of cookies
        allows us to better understand your needs regarding our content
        marketing and advertising efforts. These cookies analyze and track site
        visits and activities on the site. We share information with third
        parties that help us operate, provide, improve, integrate, customize,
        support, and market our Products. Some cookies may be disabled, which
        may affect your functionality and experience on our sites and
        applications.
      </p>
      <p><strong>First-Party Necessary / Essential Cookies</strong></p>
      <p>
        These cookies are essential for providing you with services available
        through our website and enable you to use certain features. Our
        necessary website cookies collect the following information that
        identifies and relates to you, described by the following cookies below:
      </p>
      <table>
        <tbody>
          <tr>
            <td width="86">
              <p>Strictly Necessary Cookies</p>
            </td>
            <td>
              <p>
                Strictly Necessary cookies are needed for the operation of our
                website. These cookies are essential to help you move around our
                website and use its features. We may use Strictly Necessary
                cookies to:
              </p>
              <ul>
                <li>
                  Remember products and services you selected when you get to
                  the payment page
                </li>
                <li>
                  Identify you as being logged into the website and storing
                  passwords
                </li>
                <li>Provide access to protected areas of a website</li>
                <li>
                  Remember previously entered text so it is not lost if the page
                  refreshes
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td width="86">
              <p>Performance Cookies</p>
            </td>
            <td>
              <p>
                Performance cookies help us improve the way our website works.
                We use these to analyze how the website is used and to monitor
                its performance. They tell us how pages are used, which ones are
                viewed, and whether errors occurred. This allows us to provide a
                better-quality user experience. We may use Performance cookies
                to:
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>
                  Store preferences to see which method of linking between pages
                  is most effective
                </li>
                <li>
                  Enable web analytics to provide anonymous statistics on how
                  our Website is used
                </li>
                <li>
                  To help us improve the website by measuring errors that occur
                </li>
                <li>
                  Monitor ad response rates to see how effective our ads are
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td width="86">
              <p>Functionality Cookies</p>
            </td>
            <td>
              <p>
                We use Functionality cookies to remember your preferences. We
                may use Functionality cookies to:
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>
                  Remember your applied settings. E.g., layout, colors, font,
                  and page backgrounds
                </li>
                <li>
                  Remember if we have already asked you if you want to fill in a
                  survey
                </li>
                <li>
                  Detect if you have already seen a pop-up to ensure no repeats
                </li>
                <li>
                  Process a request from a user to submit a comment or blog or
                  forum post
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td width="86">
              <p>Targeting Cookies</p>
            </td>
            <td>
              <p>
                Targeting cookies help us ensure the ads you see on our websites
                are relevant to you and your interests. Targeting cookies may be
                placed on your device by our third-party service providers that
                remember you have visited the website in order to provide you
                with ads more relevant to you. We may use Targeting cookies to:
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>
                  Collect information about browser habits to target advertising
                </li>
                <li>
                  Collect information about browser habits to target website
                  content
                </li>
                <li>Provide links to social networks</li>
                <li>
                  Provide advertising agencies with information on your visit so
                  that they can present you with advertisements which may
                  interest you
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <br>
        <br>
      </p>
      <p><strong>Third-Party Cookies</strong></p>
      <p>
        Third-party cookies are created by domains that are not websites (or
        domains) that you are visiting. These are usually used for online
        advertising purposes and are placed on a website through the addition of
        scripts or tags. A third-party cookie is accessible on websites and
        applications that load the third-party servers. We do not control the
        settings of these third-party cookies. Check the third-party websites
        for more information about their use of cookies and how to manage them.
        We use both session and persistent cookies for the purposes set out
        below:
      </p>
      <p>
        We also use cookies provided by Google Analytics to collect statistical
        information about the use of the website. You can opt out of Google
        Analytics Browser Add-on by clicking this
        link:&nbsp;http://tools.google.com/dlpage/gaoptout&nbsp;. You can also
        contact us via the contact information below. You can instruct your
        browser to refuse all cookies or to indicate when a cookie is being
        sent. However, if you do not accept cookies, you may not be able to use
        our website, products, or services.
      </p>
      <p><strong>CONTROLS FOR DO-NOT-TRACK FEATURES</strong></p>
      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>
      <p><strong>Data Protection and Retention</strong></p>
      <p>
        We use reasonable and appropriate security measures as required by
        applicable law and have employed administrative, physical, and
        electronic measures designed to protect against unauthorized access,
        loss, misuse, alteration, disclosure of, or destruction of data. We use
        data hosting service providers in the United States to host the
        information we collect, and we use technical measures to secure your
        data. When we need to transfer data out of our firewall, we use
        industry-standard technological means to protect all data while in
        transit through the Internet. We use encryption and a comprehensive
        authentication protocol to provide overall security.
      </p>
      <p><br></p>
      <p><strong>California Consumer Privacy Act and OPS</strong></p>
      <p><br></p>
      <p><strong>Your Rights and Choices</strong></p>
      <p>
        Applicable privacy laws, including the California Consumer Protection
        Act, provide consumers with specific rights regarding their personal
        information. This section describes your consumer rights and explains
        how to exercise those rights.
      </p>
      <p>
        <strong>Right to Access Specific Information and Data Portability</strong>
      </p>
      <p>
        You have the right to request that we disclose certain information to
        you about our collection and use of your personal information over the
        past 12 months. Once we receive and confirm your verifiable consumer
        request, we will disclose to you:
      </p>
      <ul>
        <li>The categories of personal information we collected about you.</li>
        <li>
          The categories of sources for the personal information we collected
          about you.
        </li>
        <li>
          Our business or commercial purpose for collecting or selling that
          personal information.
        </li>
        <li>
          The categories of third parties with whom we share that personal
          information.
        </li>
        <li>
          The specific pieces of personal information we collected about you
          (also called a data portability request).
        </li>
        <li>
          If we disclosed your personal information for a business purpose, two
          separate lists disclosing:
        </li>
        <ul>
          <li>
            sales, identifying the personal information categories that each
            category of recipient purchased; and
          </li>
          <li>
            disclosures for a business purpose, identifying the personal
            information categories that each category of recipient obtained.
          </li>
        </ul>
      </ul>
      <p><strong>&nbsp;</strong></p>
      <p><strong>Right to Delete Personal Information</strong></p>
      <p>
        You have the right to request that we delete any of your personal
        information that we collected from you and retained, subject to certain
        exceptions. Once we receive and confirm your verifiable consumer
        request, we will delete (and direct our service providers to delete)
        your personal information from our records, unless an exception applies.
      </p>
      <p>
        We may deny your deletion request if retaining the information is
        necessary for us or our service providers to:
      </p>
      <ol>
        <li>
          Complete the transaction for which we collected the personal
          information, provide a product or service that you requested, take
          actions reasonably anticipated within the context of our ongoing
          business relationship with you, or otherwise perform our contract with
          you.
        </li>
        <li>
          Detect security incidents, protect against malicious, deceptive,
          fraudulent, or illegal activity, or prosecute those responsible for
          such activities.
        </li>
        <li>
          Debug products to identify and repair errors that impair existing
          intended functionality.
        </li>
        <li>
          Exercise free speech, ensure the right of another consumer to exercise
          their free speech rights, or exercise another right provided for by
          law.
        </li>
        <li>
          Comply with the California Electronic Communications Privacy Act (Cal.
          Penal Code § 1546&nbsp;<em>seq.</em>).
        </li>
        <li>
          Enable solely internal uses that are reasonably aligned with consumer
          expectations based on your relationship with us.
        </li>
        <li>Comply with a legal obligation.</li>
        <li>
          Make other internal and lawful uses of that information that are
          compatible with the context in which you provided it.
        </li>
      </ol>
      <p><strong>&nbsp;</strong></p>
      <p>
        <strong>Right to Opt-Out of the Sale of Your Personal Information</strong>
      </p>
      <p>
        The CCPA prohibits third parties who purchase the personal information
        we hold from reselling it unless you have received explicit notice and
        an opportunity to opt out of further sales.
      </p>
      <p>
        We do not sell any of your personal information to third parties, but
        certain guidelines are instituted to prevent the mishandling of
        confidential information while fulfilling the request(s) to retrieve,
        opt out of, and/or delete such data. Users can set the ads running on
        their sites to not send personal information, such as buying history and
        browsing history, to Google. This will prevent the advertisers from
        targeting you with personalized ads. Learn how to get your ad
        preferences on all your devices.
      </p>
      <p>
        Some of your data collected from this site is used to help create
        better, more personalized products and services and to send ads and
        offers tailored to your interests. Occasionally this is done with help
        from third parties. We understand if you’d rather us not share
        your information and respect your right to disable this sharing of your
        data with third parties for this browser, device, and property. If you
        turn this off, you will not receive personalized ads, but you will still
        receive ads. Note that any choice you make here will only affect this
        website on this browser and device.
      </p>
      <p><strong>Other Opt-Out Options</strong></p>
      <p>
        <u>Opt-Out of communications</u>:&nbsp;You may opt out of receiving
        promotional communications from us by using the unsubscribe link within
        each promotional product email communication, by updating your email
        preferences, by contacting OPS Support, or by contacting us as provided
        below to have your contact information removed from our promotional
        email list or registration database. Even after you opt out from
        receiving promotional messages from us, you will continue to receive
        transactional messages (such as a receipt for a purchase) from us
        regarding our products or services. Please note that regardless of your
        email settings, we may send you notifications pertaining to the
        performance of our products or services, such as the revision of our
        Terms of Service, Privacy Policy, or other formal communications
        relating to products or services you have purchased or used.
      </p>
      <p>&nbsp;</p>
      <p>
        <u>Mobile Consents/Opt-Outs</u>: We may send you messages, including
        marketing messages, via your wireless email address or by short message
        service (SMS), wireless access protocol (WAP) services, or mobile phone
        service. By providing us with your wireless email address or by signing
        up for any SMS, WAP, or mobile phone service, you consent to receiving
        services and messages at the address or number you provided for such
        purposes and understand that your wireless carrier’s rates apply
        to these services / messages. You may opt-out of SMS, WAP, or mobile
        services by following the instructions provided in the message.
      </p>
      <p><strong>Non-Discrimination</strong></p>
      <p>
        We will not discriminate against you for exercising any of your consumer
        rights. Unless permitted by the CCPA or other applicable law, we will
        not:
      </p>
      <ul>
        <li>Deny you access to our products or services.</li>
        <li>
          Charge you different prices or rates for products or services,
          including through granting discounts or other benefits, or imposing
          penalties.
        </li>
        <li>
          Provide you a different level or quality of products or services.
        </li>
        <li>
          Suggest that you may receive a different price or rate for products or
          services or a different level or quality of products or services.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <strong>Exercising Opt Out and Access, Data Portability, Deletion
          Rights</strong>
      </p>
      <p>
        Under applicable law you may submit a request to accomplish any of the
        following:
      </p>
      <ul>
        <li>access a copy of the personal information we process about you.</li>
        <li>object to the processing of your data.</li>
        <li>access and update your information.</li>
        <li>deactivate your account.</li>
        <li>
          overview, in a commonly used format, of the data we process about you.
        </li>
        <li>
          correct or delete the data whether it is incorrect or not or it is no
          longer relevant, or to ask to restrict the processing of the data.
        </li>
        <li>
          demand that we stop using your data where you believe we don’t
          have the appropriate rights to do so.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        To opt out of the sale of your personal information and to exercise the
        access, data portability, and deletion rights described above, please
        submit a verifiable consumer request to us by any of the following:
      </p>
      <ul>
        <li>Calling us at 877-873-8729</li>
        <li>Emailing us at&nbsp;info@seeyamobile.com</li>
        <li>Writing us at</li>
      </ul>
      <p>&nbsp;</p>

      <p style="text-align: center; margin-bottom: 0">
        Overall Parts Solutions<br>
        1450 Post and Paddock Street<br>
        Grand Prairie, Texas 75050
      </p>
      <p>
        <br>
      </p>
      <p>
        Only you, or if a California resident, a person registered with the
        California Secretary of State that you authorize to act on your behalf,
        may make a verifiable consumer request related to your personal
        information. You may also make a verifiable consumer request on behalf
        of your minor child. You may only make a verifiable consumer request for
        access or data portability twice within a 12-month period. The
        verifiable consumer request must:
      </p>
      <ul>
        <li>
          Provide sufficient information that allows us to reasonably verify you
          are the person about whom we collected personal information or an
          authorized representative.
        </li>
        <li>
          Describe your request with sufficient detail that allows us to
          properly understand, evaluate, and respond to it.
        </li>
      </ul>
      <p>
        We cannot respond to your request or provide you with personal
        information if we cannot verify your identity or authority to make the
        request and confirm the personal information relates to you. Making a
        verifiable consumer request does not require you to create an account
        with us. We will only use personal information provided in a verifiable
        consumer request to verify the requestor's identity or authority to make
        the request.
      </p>
      <p>
        Your request and choices may be limited in certain other cases: for
        example, if fulfilling your request would reveal information about
        another person, or if you ask to delete information which we or your
        administrator are permitted by law or have compelling legitimate
        interests to keep. If you have unresolved concerns, you may have the
        right to complain to a data protection authority in the state where you
        live, where you work, or where you feel your rights were infringed.
      </p>
      <p><strong>Verification Process</strong></p>
      <p>
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (e.g. phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>
      <p>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. If, however, we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity, and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
      </p>
      <p><strong>Response Timing and Format</strong></p>
      <p>
        We endeavor to respond to a verifiable consumer request within 45 days
        of its receipt. If we require more time (E.g., up to 90 days, as
        permitted by the CCPA or other applicable law) we will inform you of the
        reason and extension period in writing. If you have an account with us,
        we will deliver our written response to that account. If you do not have
        an account with us, we will deliver our written response by mail or
        electronically, at your option. Any disclosures we provide will only
        cover the 12-month period preceding the verifiable consumer request's
        receipt. The response we provide will also explain the reasons we cannot
        comply with a request, if applicable. For data portability requests, we
        will select a format to provide your personal information that is
        readily useable and should allow you to transmit the information from
        one entity to another entity without hindrance.
      </p>
      <p>
        We do not charge a fee to process or respond to your verifiable consumer
        request unless it is excessive, repetitive, or manifestly unfounded. If
        we determine that the request warrants a fee, we will tell you why we
        made that decision and provide you with a cost estimate before
        completing your request.
      </p>
      <p><strong>Children’s Privacy</strong></p>
      <p>
        Our service does not address anyone under the age of 16. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 16. If you are a parent or guardian and you are aware that
        your child has provided us with personal data, please contact us. If we
        become aware that we have collected personal data from anyone under the
        age of 13 without verification of parental consent, we take steps to
        remove that information from our servers. If we need to rely on consent
        as a legal basis for processing your information and your country
        requires consent from a parent, we may require your parents’
        consent before we collect and use that information.
      </p>
      <p><strong>Changes to Our Privacy Notice</strong></p>
      <p>
        We reserve the right to amend this privacy notice at our discretion and
        at any time. When we make changes to this privacy notice, we will notify
        you by email or through a notice on our website homepage.
      </p>
      <p><strong>Contact Information</strong></p>
      <p>
        If you have any questions or comments about this notice, our Privacy
        Policy, the ways in which we collect and use your personal information,
        your choices and rights regarding such use, or wish to exercise your
        rights under California law, please do not hesitate to contact us at:
      </p>
      <p>
        <strong>Phone:</strong> 877-873-8729<br>
        <strong>Website:</strong> &nbsp;www.seeyamobile.com<br>
        <strong>Email:</strong> &nbsp;info@seeyamobile.com<br>
        <strong>Postal Address:</strong>
      </p>
      <p>&nbsp;</p>
      <p></p>
      <p style="text-align: center; margin-bottom: 0">
        Overall Parts Solutions
      </p>
      <p style="text-align: center; margin-bottom: 0">
        1450 Post and Paddock Street
      </p>
      <p style="text-align: center; margin-bottom: 0">
        Grand Prairie, TX 75050
      </p>
      <p>&nbsp;</p>
    </div>
  </div>
</div>