<div class="header">
  <div class="hero-banner">
    <ul
    >
    <li>
      <div class="caption fade-in-caption">
        <h1>The ultimate <span style="color:#ffa400;">curbside</span> experience.</h1>
        <h6 style="color:#000;line-height: 1.6;">
        The world is literally in your palm when using <strong>SeeYa! Mobile.</strong> Elevate your curbside experience by providing advance arrival notice <strong>to any business, place or person.</strong> Reduce your wait time immensely while staying safe by engaging in controlled, contactless, visitor management processes. 
        </h6>
      </div>
    </li>
      <li
        class="layer"
        data-depth=".40"
      >
        <div
          class="picture fade-in-image"
          style="
            background-image: url('/assets/img/mini-site/contactless@2x.png');
          "
        ></div>
      </li>
    </ul>
    <div class="wrapper-svg">
      <svg
        class="waves"
        fill="#ffa400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path
          style="opacity: 0.15"
          d="M 0 14 s 88.64 3.48 300 36 c 260 40 514 27 703 -10 l 12 28 l 3 36 h -1018 z"
        ></path>
        <path
          style="opacity: 0.3"
          d="M 0 45 s 271 45.13 500 32 c 157 -9 330 -47 515 -63 v 86 h -1015 z"
        ></path>
        <path
          d="M 0 58 s 188.29 32 508 32 c 290 0 494 -35 494 -35 v 45 h -1002 z"
        ></path>
      </svg>
    </div>
  </div>
  <!--Waves end-->
</div>
<!--Header ends-->

<!--Content starts-->
<!-- <div class="content flex">
  <section class="all-features">
    <div class="title text-center sides-padding">
      <h3>SeeYa! News Update</h3>
      <a (click)="getNews()" class="title-news">
        “SeeYa! For Business piloting in select locations in DFW”
      </a>
      <p class="date-news">6/24/21</p>
    </div>
    <div class="footer">
      <div class="footer__container">
        <div class="logo">
          <p>SeeYa! Mobile 2021. All Right Reserved</p>
        </div>
        <div class="menu"></div>
      </div>
    </div>
  </section>
</div> -->
<!--Content ends-->

<!-- hero section -->
<section class="hero-banner-mobile">
  <div class="hero-banner__title sides-padding grid-container">
    <div class="titles fade-in-caption">
      <h1>The ultimate <span style="color:#ffa400;">curbside</span> experience.</h1>
    </div>
    <div class="picture text-center fade-in-image">
      <picture>
        <source media="(max-width: 500px)" srcset="/assets/img/mini-site/contactless-mobile@2x.png">
        <img src="/assets/img/mini-site/contactless@2x.png" alt="seeya">
      </picture>
      <h6>
        The world is literally in your palm when using <strong>SeeYa! Mobile.</strong> Elevate your curbside experience by providing advance arrival notice <strong>to any business, place or person.</strong> Reduce your wait time immensely while staying safe by engaging in controlled, contactless, visitor management processes. 
      </h6>
    </div>
  </div>
  <div class="svg-wrapper">
    <picture>
      <source media="(max-width: 500px)" srcset="/assets/img/mini-site/shape.png">
      <img src="/assets/img/mini-site/shape.svg" alt="shapeseeya">
    </picture>
  </div>
</section>
<!-- features description -->
<section class="all-features">
  <div class="title text-center sides-padding">
    <h3>SeeYa! News Update</h3>
    <a (click)="getNews()" class="title-news">
      “SeeYa! For Business piloting in select locations in DFW”
    </a>
    <p class="date-news">
      6/24/21
    </p>
  </div>
  <div class="footer">
    <div class="footer__container">
      <div class="logo">
       
        <p>SeeYa! Mobile 2021. All Right Reserved</p>
      </div>
      <div class="menu">
        <div class="social-media">
          <ul>
            <li>
              <a (click)="getPrivacy()">Privacy Policy&nbsp; | </a>
            </li>
            <li>
              <a (click)="getTerms()">Terms of Use</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section> 
