<app-header [sectionTitle]="sectionTitle"></app-header>
<div class="container-grid news sides-padding">
  <div class="col2">
    <div class="container">
      <div class="titles">
        <h1>Terms Of Use</h1>
        <div class="line"></div>
      </div>

      <p><strong>Terms and Conditions of Use</strong></p>
      <p>
        Overall Parts Solutions, Inc., a Texas corporation, as well as its
        affiliates and subsidiaries, (collectively referred to throughout
        as&nbsp;"OPS,"&nbsp;"we"&nbsp;or&nbsp;"us"), offer a variety of products
        and services, including SeeYa! websites and applications&nbsp;("SeeYa!
        Mobile, SeeYa! For Business, SeeYa! Valet, " etc.), and content; our
        e-commerce websites and applications&nbsp;("seeyamobile.com, "
        etc.);&nbsp;and all other current and future digital products and
        service offerings we make available, including any products services,
        and content provided in collaboration with our partners (collectively,
        the&nbsp;"Services" or “OPS Services”).
      </p>
      <p>
        THESE TERMS AND CONDITIONS OF USE (“TERMS”) INCLUDE A
        BINDING ARBITRATION CLAUSE AND A CLASS ACTION WAIVER. THIS PROVISION
        AFFECTS YOUR RIGHTS TO RESOLVE DISPUTES WITH OPS AND YOU SHOULD REVIEW
        IT CAREFULLY. YOUR CHOICE TO MAINTAIN AN ACCOUNT, ACCESS OR USE THE
        SERVICES (REGARDLESS OF WHETHER YOU CREATE AN ACCOUNT WITH US)
        CONSTITUTES YOUR EXPRESS AND UNCODNITIONAL AGREEMENT TO FOLLOW AND BE
        BOUND BY THESE TERMS AND OUR&nbsp;PRIVACY POLICY. IF YOU DISAGREE WITH
        ANY PART OF THE TERMS, THEN YOU ARE NOT PERMITTED TO USE OUR SERVICES,
        AND WE REQUEST THAT YOU IMMEDIATELY DISCONTINUE ANY USE THEREOF.
      </p>
      <p>
        ALWAYS DRIVE VIGILANTLY ACCORDING TO ROAD CONDITIONS AND IN ACCORDANCE
        WITH TRAFFIC LAWS. IT IS STRICTLY FORBIDDEN TO SEND ANY CONTENT -
        INCLUDING TRAFFIC UPDATES (SUCH AS UPDATES ON ROAD ACCIDENTS AND TRAFFIC
        CONGESTION) WHILE DRIVING. YOUR CONTENT SUBMISSIONS MAY ONLY BE SENT
        AFTER YOU HAVE STOPPED YOUR VEHICLE IN AN APPROPRIATE LOCATION PERMITTED
        BY LAW. ALTERNATIVELY, CONTENT MAY BE SENT BY A PASSENGER OTHER THAN THE
        DRIVER, PROVIDED IT DOES NOT INTERFERE WITH THE DUE COURSE OF DRIVING
        AND DOES NOT DISTRACT THE DRIVER.
      </p>
      <p>
        YOU MUST NOT ATTEMPT TO VIEW OR CLICK ON ANY ADS, OR OTHERWISE INTERACT
        WITH YOUR DEVICE WHILE OPERATING A VEHICLE. YOU ALONE ARE RESPONSIBLE
        FOR DRIVING RESPONSIBLY, AND YOU ACKNOWLEDGE AND AGREE THAT IN THE EVENT
        THAT YOU VIOLATE THE FOREOING PROVISION, THE THIRD PARTY ADVERTISERS
        WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL OR CONSEQUENTIAL
        DAMAGE, OR ANY OTHER DAMAGE, AND LOSS (INCLUDING LOSS OF PROFIT AND LOSS
        OF DATA), COSTS, EXPENSES AND PAYMENTS, EITHER IN TORT, CONTRACTUAL, OR
        IN ANY OTHER FORM OF LIABILITY, ARISING FROM, OR IN CONNECTION WITH THE
        DISPLAY OF THE ADS ON THE SERVICE OR YOUR VIEWING OF THE ADS ON THE
        SERVICE.
      </p>
      <p>
        Please note that by accessing or using our Services, any Data that you
        share with any person, part, division, or application of OPS or the
        Services may also be shared among the entirety of OPS and its Services
        with no liability to OPS or any of the Services as a result thereof.
      </p>
      <ol>
        <li><strong> Use of the Services and Your Account</strong></li>
      </ol>
      <p><strong>1.1 Who can use the OPS Services</strong></p>
      <p>
        You must be at least 16 to use OPS’ Services. No individual under
        this age limit may use the Services, provide any Personal Data to OPS,
        or otherwise submit Personal Data through the Services (e.g., a name,
        address, telephone number, or email address), and OPS shall not be
        liable for any damage arising out of any unauthorized use of the
        Services in violation of this section.
      </p>
      <ul>
        <li>
          Road information prevails. The information provided by the Service is
          not intended to replace the information provided on the road, such as
          travel direction, time based restrictions, lane restrictions, road
          blockades, traffic signs, traffic lights, police instructions, etc.
        </li>
        <li>
          Cautious driving. Always drive vigilantly according to road conditions
          and in accordance with traffic laws. It is strictly forbidden to send
          traffic updates (such as updates on road accidents and traffic
          congestion), or to non-verbally interact with the Service or use the
          Service in a non-verbal manner for any purpose other than navigation
          while driving. Traffic updates or non-verbal reports you want to
          submit to the Service may only be sent after you have stopped your
          vehicle in an appropriate location permitted by law. Alternatively,
          such updates may be sent by a passenger other than the driver,
          provided it does not interfere with the due course of driving and does
          not distract the driver’s attention to the road. Any
          notifications or other alerts by the Service should not be interacted
          with in a non-verbal manner until the vehicle is stationary or
          interacted with by a passenger other than the drive, even if the
          vehicle is at a business location.&nbsp; For clarity, the Service
          should not be used by a driver unless the vehicle is stationary.
        </li>
      </ul>
      <p>
        <br>
        <strong>
        1.2 Your Account
        </strong>
      </p>
      <p>
        You may need to register for an account to access or use certain
        Services. Your account may also automatically provide you with access to
        and the means to use any new Services.
      </p>
      <p>
        When you create an account for any of the Services, you must provide
        accurate and complete information as prompted by the account creation
        and registration process and must at all times keep that information
        accurate, complete, and up to date. The failure to do so may cause the
        Services to operate incorrectly, and we may not be able to contact you
        with important notices.
      </p>
      <p>
        You are responsible for maintaining the confidentiality of any and all
        information related to your use of the Services and any actions that
        take place while using the Services or your account, including, but not
        limited to, your usernames and/or passwords, and you must notify the our
        support team immediately of any actual or suspected loss, theft, or
        unauthorized use of your username, account, or account password. We are
        not responsible for any loss that results from unauthorized use of your
        username, account, and/or password, or any other information related to
        the use of the Services.
      </p>
      <p><strong>1.3 Service Updates, Changes and Limitations</strong></p>
      <p>
        Our Services are constantly evolving. With the launch of new products,
        services, and features, we need the flexibility to make changes, impose
        limits, and occasionally suspend or terminate certain Services. We may
        also update our Services, which might not work properly if you fail to
        install the updates.&nbsp;&nbsp;&nbsp;&nbsp; <br>
      </p>
      <p>
        The Services change frequently, and their form and functionality may
        change without prior notice to
        you.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <br>
        <br>
        We may provide updates (including automatic updates) for certain
        Services as and when we see fit. This may include upgrades,
        modifications, bug fixes, patches and other error corrections and/or new
        features (collectively, "Updates"). Certain portions of our Services may
        not properly operate if you do not fail to install or if you do not
        allow such Updates.&nbsp; Your continued use of the Services following
        any Updates constitutes your express agreement to continue to follow and
        be bound by the Terms, and by using the Services, you expressly consent
        to automatic Updates. Further, you agree that the Terms (and any
        additional modifications of the same) will apply to any and all Updates
        to the Services. To the extent permitted by applicable laws,
        regulations, authorities, (the “Applicable Laws”), we may
        change, suspend, or discontinue any or all of the Services at any time,
        including the availability of any product, feature, database, or
        content. In addition, we have no obligation to provide any Updates or to
        continue to provide or enable any particular features or functionality
        of any Service. We may also impose limits on certain Services or
        restrict your access to part or all of the Services without notice or
        liability as a result thereof.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <br>
      </p>
      <p><strong>1.4 Service Monitoring and Suspension</strong></p>
      <p>
        We reserve the right to refuse to provide the Services to anyone, and to
        the extent permitted by the Applicable Laws, we can monitor, terminate
        or suspend your account or access to the Services at any time. <br>
        <br>
        To the extent permitted by the Applicable Laws, we reserve the right,
        but have no obligation, to monitor any accounts and/or activities
        conducted through or in any way related to the Services, as well as any
        user's use of or access to Personal Data, and profiles of other users.
        We may also deactivate, terminate or suspend your account or access to
        certain Services at any time: (1) if we, in our sole discretion,
        determine you are or have been in violation of these Terms, (2) if we,
        in our sole discretion, determine you have created risk or possible
        legal exposure for us, the general public, any third party, or any user
        of our Services, (3) as required by law, pursuant to court order, in
        response to requests by law enforcement, or other government agencies,
        (4) upon discontinuation or material modification of any Services, or
        (5) due to unexpected technical issues or problems. We will attempt to
        notify you by email or the next time you attempt to access your account
        after any such deactivation, termination or
        suspension.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <br>
      </p>
      <p><strong>1.5 Security</strong></p>
      <p>
        You must notify us immediately of any actual or suspected loss, theft,
        or unauthorized use of your account, or if you suspect your account has
        been hacked or compromised in any way.
      </p>
      <p>
        We makes no representation, warranty, or guarantee regarding the
        security of your Personal Data and account. You must notify our Support
        Team immediately of any actual or suspected breach or unauthorized
        access or use of your account.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <p><strong>1.6 Payment and Automatic Renewal</strong></p>
      <p>
        By establishing an account and accepting these Terms, you authorize us
        to charge the credit card or debit the account that you have designated
        and provided to us, or charge the business that your account is
        associated with subject to these Terms and any other applicable
        agreement(s), as follows: (a) your account will automatically renew and
        you will be automatically charged your monthly subscription payment for
        use of the Services each month, on the first (1<sup>st</sup>) day of
        each calendar month, until your account and/or your use of the Services
        is terminated by you or OPS.&nbsp;
      </p>
      <p>
        You further agree that the payment card specified by you, or the
        business that your account is associated with, for automatic monthly
        payments to OPS is, and will continue to be, an account that you own,
        and that you will maintain sufficient availability under your credit
        card limit, or sufficient funds in the account linked to your debit
        account, as applicable, to pay your monthly designated amount.
      </p>
      <p>
        These Terms will constitute your copy of your recurring payment
        authorization to OPS. Please print and retain a copy of this recurring
        payment authorization for your records.
      </p>
      <p>
        You, or the business that your account is associated with, may cancel
        your recurring payment authorization at any time, by contacting us as
        provided in Section 12 of these Terms. Your request to cancel your
        recurring payment authorization must be received at least ten (10)
        business days prior to the due date for your recurring payment for the
        following month. If your cancellation request is submitted after this
        time, the cancellation will not take effect until the following billing
        cycle.
      </p>
      <p>
        To the extent permitted under the Applicable Laws, you are responsible
        for any applicable taxes, whether or not they are listed on your receipt
        or statement. We reserve the right, upon prior notice to you, to change
        the amount of any fees and to institute new fees. If payment cannot be
        charged to your credit card or designated account or your payment is
        returned to OPS for any reason, OPS reserves the right to either suspend
        or terminate your access to the Services. It is your responsibility to
        ensure that sufficient funds are available to cover the charges for the
        Services, and OPS has no liability for any overdraft or other fees that
        you may incur as a result of OPS processing your payment.
      </p>
      <p>
        In the event legal action is necessary to collect on balances due, you
        agree to reimburse OPS for all expenses incurred to recover sums due,
        including, but not limited to, attorney’s fees and other legal
        expenses.
      </p>
      <p><strong>2 Ownership and Use of Data</strong></p>
      <p><strong>2.1 Definitions</strong></p>
      <p>
        For purposes of these Terms, "Data" means any form of information, data
        or creative expression and includes, without limitation, video, audio,
        photographs, images, illustrations, animations, tools, text, ideas,
        communications, replies, comments, software, scripts, graphics, maps,
        routes, geo-data, interactive features, designs, copyrights, trademarks,
        service marks, branding, logos, and other similar assets, patents,
        sounds, applications and any intellectual property therein, any of which
        may be generated, provided, or otherwise made accessible on or through
        the Services. "OPS Content" means all content that is not user-generated
        data.
      </p>
      <p>
        <br>
        <strong>2.2 Ownership</strong>
      </p>
      <p>
        All OPS Content and all copyright, trademarks, design rights, patents
        and other intellectual property rights (registered and unregistered) in
        the Services belong to OPS and/or its partners or applicable third
        parties. Each user retains responsibility for, and/or other applicable
        rights in the Data that he/she creates but grants a license and
        Ownership of that Data to OPS as explained in Sections 2.3 and 2.5
        below. OPS and/or its partners or third parties retain ownership,
        responsibility for and/or other applicable rights in all OPS Content and
        Data that is licensed. Except as expressly provided in the Terms,
        nothing grants you a right or license to use any OPS Content, including
        any content owned or controlled by any of our partners or other third
        parties. By virtue of accessing or using the Services, you agree not to
        duplicate, publish, display, distribute, modify, or create derivative
        works from the material presented through the Services unless
        specifically authorized in writing by OPS.
      </p>
      <p>
        <br>
        <strong>2.3 OPS License to Users</strong>
      </p>
      <p>
        Subject to your compliance with these Terms, OPS grants to you a
        limited, revocable, personal, non-transferable, and non-exclusive right
        and license to access and use the Services and OPS Content for your
        commercial purposes, provided that you shall not (and shall not allow
        any third party to) copy, modify, create a derivative work from, reverse
        engineer, sell, assign, sublicense, grant a security interest in,
        transfer or otherwise commercially exploit any right in the OPS Content
        or Services without the express prior written consent of OPS, which may
        be withheld in the sole discretion of OPS.
      </p>
      <p><strong>2.4 Acceptable Usage Guidelines</strong></p>
      <p><strong>2.4.1 OPS Content.&nbsp;</strong></p>
      <p>
        Except as expressly permitted by the Applicable Laws or expressly
        authorized in writing by Overall Parts Solutions, you agree not to
        modify, rent, lease, loan, sell, distribute, or create derivative works
        based on the Services, the Services' software, or any OPS Content
        offered as part of the Services, in whole or in part. You shall not
        download, copy, or save OPS Content, except (i) as expressly permitted
        by the functionality of certain Services (e.g., printed invoices), as
        provided for in the specific guidelines and/or additional terms
        applicable to those Services.
      </p>
      <p><strong>2.4.2 Commercial Usage of the Services.&nbsp;</strong></p>
      <p>
        The Services are intended only for your commercial use. You shall not
        use the Services to increase traffic to any personal or other commercial
        website, or any third-party website, or for the purpose of circumventing
        OPS Services, or otherwise undertake any endeavor aimed at deriving
        revenue without the knowledge or express prior written consent of OPS.
        For example, you shall not take the results from a search of the
        Services and reformat and display them or mirror any images, designs,
        intellectual property, or any other information included in or accessed
        through the Services on any personal or other commercial website or
        other publication medium. In the event that you seek to make commercial
        use of the Services, you must enter into a written agreement duly
        executed by OPS prior to engaging in any such commercial use.
      </p>
      <p><strong>2.4.5 Prohibited Activities</strong></p>
      <p>
        You may not access or use the Site for any purpose other than that for
        which we make the Site available. The Site may not be used in connection
        with any commercial endeavors except those that are specifically
        endorsed or approved by us.&nbsp; As a user of the Site, you agree not
        to:
      </p>
      <ol>
        <li>
          Systematically retrieve data or other content from the Site to create
          or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us.
        </li>
        <li>
          Trick, defraud, or mislead us and other users, especially in any
          attempt to learn sensitive account information such as user passwords.
        </li>
        <li>
          Circumvent, disable, or otherwise interfere with security-related
          features of the Site, including features that prevent or restrict the
          use or copying of any Content or enforce limitations on the use of the
          Site and/or the Content contained therein.
        </li>
        <li>
          Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
          Site.
        </li>
        <li>
          Use any information obtained from the Site in order to harass, abuse,
          or harm another person.
        </li>
        <li>
          Make improper use of our support services or submit false reports of
          abuse or misconduct.
        </li>
        <li>
          Use the Site in a manner inconsistent with any applicable laws or
          regulations.
        </li>
        <li>Engage in unauthorized framing of or linking to the Site.</li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material, including excessive use of capital
          letters and spamming (continuous posting of repetitive text), that
          interferes with any party’s uninterrupted use and enjoyment of
          the Site or modifies, impairs, disrupts, alters, or interferes with
          the use, features, functions, operation, or maintenance of the Site.
        </li>
        <li>
          Engage in any automated use of the system, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools.
        </li>
        <li>
          Attempt to impersonate another user or person or use the username of
          another user.
        </li>
        <li>Sell or otherwise transfer your profile.</li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) any material
          that acts as a passive or active information collection or
          transmission mechanism, including without limitation, clear graphics
          interchange formats (“gifs”), 1×1 pixels, web bugs,
          cookies, or other similar devices (sometimes referred to as
          “spyware” or “passive collection mechanisms”
          or “pcms”).
        </li>
        <li>
          Interfere with, disrupt, or create an undue burden on the Site or the
          networks or services connected to the Site.
        </li>
        <li>
          Harass, annoy, intimidate, or threaten any of our employees or agents
          engaged in providing any portion of the Site to you.
        </li>
        <li>
          Attempt to bypass any measures of the Site designed to prevent or
          restrict access to the Site, or any portion of the Site.
        </li>
        <li>
          Copy or adapt the Site’s software, including but not limited to
          Flash, PHP, HTML, JavaScript, or other code.
        </li>
        <li>
          Decipher, decompile, disassemble, or reverse engineer any of the
          software comprising or in any way making up a part of the Site.
        </li>
        <li>
          Except as may be the result of standard search engine or Internet
          browser usage, use, launch, develop, or distribute any automated
          system, including without limitation, any spider, robot, cheat
          utility, scraper, or offline reader that accesses the Site, or using
          or launching any unauthorized script or other software.
        </li>
        <li>
          Use a buying agent or purchasing agent to make purchases on the Site.
        </li>
        <li>
          Make any unauthorized use of the Site, including collecting usernames
          and/or email addresses of users by electronic or other means for the
          purpose of sending unsolicited email, or creating user accounts by
          automated means or under false pretenses.
        </li>
        <li>Use the Site as part of any effort to compete with us.</li>
      </ol>
      <p>
        <strong>2.5 Users’ License to OPS.&nbsp;</strong>
      </p>
      <p>
        To the extent permitted by the Applicable Laws, when providing Data to
        OPS through the Services, you grant to OPS and its users a
        non-exclusive, irrevocable, royalty-free, freely transferable, worldwide
        right and license to use, host, store, cache, reproduce, display,
        perform, distribute, transmit, modify, adapt (including, without
        limitation, in order to conform to the requirements of any networks,
        devices, services, or media through which the Services are available),
        and commercialize such Data in connection with any and all Services.
      </p>
      <p>
        The rights you grant in this license are for the limited purposes of
        allowing OPS to operate and allow other users to use the Services in
        accordance with their functionality, improve the Services, and develop
        new Services. Notwithstanding the above, OPS will not make use of any
        user Data in a manner that is inconsistent with the privacy settings
        established by users within the Services. For information on managing
        privacy settings for the Services, see the&nbsp;<a href="https://www.seeyamobile.com/policy">Privacy Policy.</a>
      </p>
      <p>
        To the extent permitted by the Applicable Laws, OPS reserves the right
        to monitor, remove or modify Data for any reason and at any time,
        including Data that OPS believes violates these Terms, and/or its
        policies.
      </p>
      <p>
        By virtue of accessing or using the Services, you agree you will respect
        the intellectual property rights of others, and you represent and
        warrant that you have all the necessary rights to grant OPS the
        foregoing license for all Data submitted in connection with the
        Services.&nbsp; By virtue of accessing and using the Services, you agree
        to indemnify, defend, and hold harmless OPS from any liability related
        to any breach of this representation and warranty
      </p>
      <p>
        Users grant OPS ownership and licenses, and all right, title, and
        interest it is allowed in the Data.&nbsp; In particular, that data which
        is generated by use of the Services.&nbsp; The user has the right to
        request withdrawal of the license and deletion of their Data, which
        shall include any data that has not been made anonymous by removing
        personal identifying information or data.&nbsp; OPS shall have the right
        to continue to use any Data that has been made anonymous, even after
        receiving a deletion request in accordance with the terms of the Privacy
        Policy.&nbsp;
      </p>
      <p><strong>2.5.1 User Generated Contributions</strong></p>
      <ol>
        <li>
          The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of your
          Contributions do not and will not infringe the proprietary rights,
          including but not limited to the copyright, patent, trademark, trade
          secret, or moral rights of any third party.
        </li>
        <li>
          You are the creator and owner of or have the necessary licenses,
          rights, consents, releases, and permissions to use and to authorize
          us, the Site, and other users of the Site to use your Contributions in
          any manner contemplated by the Site and these Terms of Use.
        </li>
        <li>
          You have the written consent, release, and/or permission of each and
          every identifiable individual person in your Contributions to use the
          name or likeness of each and every such identifiable individual person
          to enable inclusion and use of your Contributions in any manner
          contemplated by the Site and these Terms of Use.
        </li>
        <li>Your Contributions are not false, inaccurate, or misleading.</li>
        <li>
          Your Contributions are not unsolicited or unauthorized advertising,
          promotional materials, pyramid schemes, chain letters, spam, mass
          mailings, or other forms of solicitation.
        </li>
        <li>
          Your Contributions are not obscene, lewd, lascivious, filthy, violent,
          harassing, libelous, slanderous, or otherwise objectionable (as
          determined by us).
        </li>
        <li>
          Your Contributions do not ridicule, mock, disparage, intimidate, or
          abuse anyone.
        </li>
        <li>
          Your Contributions are not used to harass or threaten (in the legal
          sense of those terms) any other person and to promote violence against
          a specific person or class of people.
        </li>
        <li>
          Your Contributions do not violate any applicable law, regulation, or
          rule.
        </li>
        <li>
          Your Contributions do not violate the privacy or publicity rights of
          any third party.
        </li>
        <li>
          Your Contributions do not contain any material that solicits personal
          information from anyone under the age of 18 or exploits people under
          the age of 18 in a sexual or violent manner.
        </li>
        <li>
          Your Contributions do not violate any applicable law concerning child
          pornography, or otherwise intended to protect the health or well-being
          of minors.
        </li>
        <li>
          Your Contributions do not include any offensive comments that are
          connected to race, national origin, gender, sexual preference, or
          physical handicap.
        </li>
        <li>
          Your Contributions do not otherwise violate, or link to material that
          violates, any provision of these Terms of Use, or any applicable law
          or regulation.
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        Any use of the Site in violation of the foregoing violates these Terms
        of Use and may result in, among other things, termination or suspension
        of your rights to use the Site.
      </p>
      <p>&nbsp;</p>
      <p><strong>2.6 Data Retention.&nbsp;</strong></p>
      <p>
        In the event of termination of your account, OPS may retain your Data
        for a commercially reasonable period of time for backup, archival, or
        audit purposes, or as otherwise required or permitted by the Applicable
        Laws. Furthermore, OPS may retain and continue to use, store, reproduce,
        and modify any of user Data that otherwise has been stored or shared
        through the Services. The license to your Data granted pursuant to
        Section 2.5 above shall survive the cessation of use of the Services
        and/or the termination of any agreement to use the Services.
      </p>
      <p> <strong>3. Mobile Services</strong></p>
      <p><strong>3.1 Wireless Carrier and Device Considerations.&nbsp;</strong> </p>
      <ul>
        <li>
          Using or accessing the Services and/or the applications requires a
          compatible device. OPS makes no representations, warranties, or
          guarantees that the Services or the applications will be compatible
          with, or available on, any particular cell phone, computer, software,
          operating system, media platform, or other user device. Standard data
          and messaging rates apply to the use of the Services and applications,
          and any applicable rules, terms, conditions, or charges of any
          third-party supplier, including, but not limited to, any “app
          store” supplying the applications and/or the Services may also
          apply. The Internet connection required to use the Service, and any
          associated charges (e.g. mobile data expenses) incurred by your use of
          the Service are your exclusive responsibility and made solely at your
          expense. Transmitting and receiving real-time updates to and from the
          Service, requires an online (e.g. Wi-Fi, 3G, 4G, 5G, etc.) connection
          between your cellular device and the Internet. The expenses of such
          connection are as prescribed by the agreement between you and your
          communication service provider (such as your cellular company), and
          according to its applicable terms of payment.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p><strong>3.2 Text and Mobile Messaging Consents.&nbsp;</strong> </p>
      <p>
        By downloading or using our applications, you expressly agree that we
        may communicate with you regarding transactions you have initiated
        through the Services or any OPS application, and you expressly authorize
        us to respond to any communications from you through the Services by
        SMS, MMS, text message, push notification, or other electronic means
        directed to your devices and you further agree that, to the extent
        permitted by the Applicable Laws, certain information about your usage
        of the applications may be communicated to us automatically from your
        device. We will not send you direct messages of a marketing nature.
      </p>
      <p><strong>3.3 Mobile Application License.&nbsp;</strong> </p>
      <p>
        OPS grants you a limited, personal, non-exclusive, non-transferable,
        non-sublicensable, revocable license to use OPS applications downloaded
        directly from a legitimate marketplace, solely in object code format and
        solely for your commercial use for lawful purposes. With respect to any
        open source or third-party code that may be incorporated in the
        applications, such open source code is covered by the applicable open
        source, if any, authorizing use of such code.&nbsp; You shall not: (1)
        decompile, reverse engineer, disassemble, attempt to derive the source
        code of, or decrypt the application; (2) make any modification,
        adaptation, improvement, enhancement, translation, or derivative work
        from the application; (3) violate any applicable laws, rules, or
        regulations in connection with your access or use of the application;
        (4) remove, alter, or obscure any proprietary notice (including any
        notice of copyright or trademark) posted by us or the licensors of the
        application; (5) use the application for any purpose for which it is not
        designed or intended; (6) make the application available over a network
        or other environment permitting access or use by multiple devices or
        users at the same time; (7) use the application for creating a product,
        service, or software that is, directly or indirectly, competitive with
        or in any way a substitute for the application; (8) use the application
        to send automated queries to any website or to send any unsolicited
        commercial e-mail; or (9) use any proprietary information or any of our
        interfaces or our other intellectual property in the design,
        development, manufacture, licensing, or distribution of any
        applications, accessories, or devices for use with the application.
      </p>
      <p><strong>3.4 App Stores.&nbsp;</strong> </p>
      <p>
        In the event that you download our applications from a third-party
        supplier, including, but not limited to, any app store (the "App
        Provider"), you acknowledge and agree that:
      </p>
      <ul>
        <li>
          The Terms constitute an agreement between you and OPS, and do not
          involve the App Provider. As between OPS and the App Provider, OPS is
          solely responsible for its applications;
        </li>
        <li>
          The App Provider has no obligation to provide any maintenance and
          support services with respect to the OPS applications;
        </li>
        <li>
          In the event of any failure of the OPS applications to conform to any
          applicable warranty, (i)you may notify the App Provider and the App
          Provider may refund the purchase price for the applications to you (if
          applicable), (ii) to the maximum extent permitted by the Applicable
          Laws, the App Provider will have no other warranty obligation
          whatsoever with respect to the applications, and (iii) any other
          claims, losses, liabilities, damages, costs or expenses attributable
          to any failure to conform to any warranty will be, as between OPS and
          the App Provider, OPS's responsibility;
        </li>
        <li>
          The App Provider is not responsible for addressing any claims relating
          to the applications or possession and use of the applications;
        </li>
        <li>
          If a third party claims an application infringes another party's
          intellectual property rights, as between the App Provider and OPS, OPS
          will be responsible for the investigation, defense, settlement and
          discharge of any such claim to the extent required by these Terms;
        </li>
        <li>
          You must comply with all applicable third-party terms of service when
          using the Services and the OPS applications.
        </li>
      </ul>
      <p><strong>&nbsp;</strong></p>
      <p><strong>3.4.1 Apple and Android Devices</strong> </p>
      <p>
        The following terms apply when you use a mobile application obtained
        from either the Apple Store or Google Play (each an “App
        Distributor”) to access the Site: (1) the license granted to you
        for our mobile application is limited to a non-transferable license to
        use the application on a device that utilizes the Apple iOS or Android
        operating systems, as applicable, and in accordance with the usage rules
        set forth in the applicable App Distributor’s terms of service;
        (2) we are responsible for providing any maintenance and support
        services with respect to the mobile application as specified in the
        terms and conditions of this mobile application license contained in
        these Terms of Use or as otherwise required under applicable law, and
        you acknowledge that each App Distributor has no obligation whatsoever
        to furnish any maintenance and support services with respect to the
        mobile application; (3) in the event of any failure of the mobile
        application to conform to any applicable warranty, you may notify the
        applicable App Distributor, and the App Distributor, in accordance with
        its terms and policies, may refund the purchase price, if any, paid for
        the mobile application, and to the maximum extent permitted by
        applicable law, the App Distributor will have no other warranty
        obligation whatsoever with respect to the mobile application; (4) you
        represent and warrant that (i) you are not located in a country that is
        subject to a U.S. government embargo, or that has been designated by the
        U.S. government as a “terrorist supporting” country and (ii)
        you are not listed on any U.S. government list of prohibited or
        restricted parties; (5) you must comply with applicable third-party
        terms of agreement when using the mobile application, e.g., if you have
        a VoIP application, then you must not be in violation of their wireless
        data service agreement when using the mobile application; and (6) you
        acknowledge and agree that the App Distributors are third-party
        beneficiaries of the terms and conditions in this mobile application
        license contained in these Terms of Use, and that each App Distributor
        will have the right (and will be deemed to have accepted the right) to
        enforce the terms and conditions in this mobile application license
        contained in these Terms of Use against you as a third-party beneficiary
        thereof.
      </p>
      <p><strong>4 Updates to these Terms</strong></p>
      <p>
        OPS reserves the right to modify these Terms by (i) posting revised
        Terms on and/or through the Services, and/or (ii) providing advance
        notice to you of material changes to the Terms, generally via email
        where practicable, and otherwise through the Services (such as through a
        notification on the home page of the SeeYa! website or in the
        applications). Modifications will not apply retroactively unless
        required by law.
      </p>
      <p>
        OPS reserves the right at any time to request that you review and
        explicitly agree to or reject a revised version of the Terms. In such
        cases, modifications will be effective at the time of your agreement to
        the modified version of the Terms. If you do not agree at that time, you
        are not permitted to use the Services and you shall immediately
        discontinue any access to or use thereof. In cases where OPS does not
        ask for your explicit agreement to a modified version of the Terms, the
        modified version of the Terms will become effective as of the date
        specified in the Terms. By virtue of maintaining an account, accessing,
        or using the Services and/or any application (regardless of whether you
        create an account) you expressly agree to accept, follow, and be bound
        by the terms and conditions of the Terms as modified.
      </p>
      <p><strong>5 No Warranties</strong></p>
      <p>
        EXCEPT WHERE PROHIBITED BY LAW, OPS EXPRESSLY DISCLAIMS ALL WARRANTIES,
        REPRESENTATIONS AND GUARANTEES OF ANY KIND, WHETHER ORAL OR WRITTEN,
        EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO
        <strong>WARRANTIES RELATED TO ANY COURSE OF DEALING, USAGE, OR TRADE
          PRACTICE, </strong>THE IMPLIED WARRANTIES OF MERCHANTABILITY, SERVICES FOR A PARTICULAR
        PURPOSE AND NON-INFRINGEMENT TO THE FULLEST EXTENT PERMISSIBLE UNDER THE
        LAW. THE SERVICES AND ALL CONTENT ARE PROVIDED ON AN "AS IS" AND "AS
        AVAILABLE" WITH ALL FAULTS BASIS.
      </p>
      <p>
        Without limiting the foregoing, and to the maximum extent permitted by
        the Applicable Laws, OPS makes no warranty or representation regarding
        the quality, accuracy, timeliness, truthfulness, completeness,
        availability, or reliability of any of the Services or any Data. To the
        maximum extent permitted by the Applicable Laws, OPS does not warrant or
        represent that (i) the Services will meet your requirements or provide
        specific results, (ii) the operation of the Services will be
        uninterrupted, virus- or error-free or free from other harmful elements
        or that (iii) any errors or defects in the Services will be corrected.
        Any oral or written advice provided by OPS or its agents, employees, or
        representatives does not and will not create any warranty. To the
        maximum extent permitted by the Applicable Laws, OPS makes no
        representations or warranties of any kind with respect to Data, in
        particular, when such Data is provided by and is solely the
        responsibility of the users providing that Data. No advice or
        information, whether oral or written, obtained from other users or
        through the Services, will create any warranty not expressly made
        herein. You therefore expressly acknowledge and agree to assume the risk
        of any damage or loss arising out of or related to the use of the
        Services, and OPS makes no warranty or representation as to the quality,
        performance, or accuracy of the Services.
      </p>
      <p>
        Additionally and without derogating from the above clause, OPS disclaims
        any warranties relating to the accuracy of the maps, Content, road
        conditions, driving directions, or navigation routes presented or
        displayed in or by the Service. For instance, traffic may be congested
        in roads depicted by the Service as uncongested; existing roads may be
        missing from the map; users may submit faulty or inaccurate Content or
        reports. Such errors and omissions are inherent to any community-based
        service that operates on users’ posts and on the information
        provided by them.
      </p>
      <p><strong>6 Limitation of Liability</strong></p>
      <p>
        To the maximum extent permitted by the Applicable Laws, under no
        circumstances (including, without limitation, negligence) shall OPS, its
        subsidiaries, partners or any affiliate be liable to you, any user of
        the Services or any third party for (a) any indirect, incidental,
        special, reliance, exemplary, punitive, or consequential damages of any
        kind whatsoever; (b) loss of profits, revenue, data, use, goodwill, or
        other intangible losses; (c) damages relating to your access to, use of,
        or inability to access or use the Services; (d) damages relating to any
        conduct or content of any third party or user of the Services, including
        without limitation, defamatory, offensive or illegal conduct or content;
        and/or (e) damages in any manner relating to any third-party content,
        third-party products or third-party activities accessed via the
        Services. To the maximum extent permitted by the Applicable Laws, this
        limitation applies to all claims, whether based on warranty, contract,
        tort, or any other legal theory, whether or not OPS has been informed of
        the possibility of such damage, and further where a remedy set forth
        herein is found to have failed its essential purpose. In particular, to
        the extent permitted by the Applicable Laws, OPS shall not be not liable
        for any claims arising out of (a) your use of the Services (including,
        but not limited to, your participation in any activities promoted by or
        accessed via the Services), (b) the use, disclosure, display, or
        maintenance of a user’s Personal Data, (c) any other interactions
        with OPS or any other users of the Services, even if OPS has been
        advised of the possibility of such damages, or (d) other Data,
        information, services or goods received through or advertised on the
        Services or received through any links provided with the Services. To
        the extent permitted by the Applicable Laws, you acknowledge and agree
        that OPS offers the Services and set the Services' prices in reliance
        upon the warranty disclaimers, releases, and limitations of liability
        set forth in the Terms, that these warranty disclaimers, releases, and
        limitations of liability reflect a reasonable and fair allocation of
        risk between you and OPS, and such terms form an essential basis of the
        bargain between you and OPS.&nbsp; Furthermore, and to the extent
        permitted by the Applicable Laws, no legal action related to any loss or
        damage arising out of the use of the Services may proceed against OPS
        unless commenced within two (2) years of the date on which such loss or
        damage occurred.
      </p>
      <p>
        If you are a resident of California:&nbsp;You waive your rights with
        respect to California Civil Code Section 1542, which says:
      </p>
      <p>
        A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR
        RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT
        THE TIME OF EXECUTING THE RELEASE AND THAT IF KNOWN BY HIM OR HER WOULD
        HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR
        RELEASED PARTY.
      </p>
      <p>
        YOU MUST NOT ATTEMPT TO VIEW OR CLICK ON ANY ADS, OR OTHERWISE INTERACT
        WITH THE DEVICE WHILE OPERATING A VEHICLE. YOU ALONE ARE RESPONSIBLE FOR
        DRIVING RESPONSIBLY, AND YOU ACKNOWLEDGE AND AGREE THAT IN THE EVENT
        THAT YOU VIOLATE THE FOREOING PROVISION, THE THIRD PARTY ADVERTISERS
        WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL OR CONSEQUENTIAL
        DAMAGE, OR ANY OTHER DAMAGE, AND LOSS (INCLUDING LOSS OF PROFIT AND LOSS
        OF DATA), COSTS, EXPENSES AND PAYMENTS, EITHER IN TORT, CONTRACTUAL, OR
        IN ANY OTHER FORM OF LIABILITY, ARISING FROM, OR IN CONNECTION WITH THE
        DISPLAY OF THE ADS ON THE SERVICE OR YOUR VIEWING OF THE ADS ON THE
        SERVICE.
      </p>
      <p>
        You agree and acknowledge that you assume full, exclusive and sole
        responsibility for the use of and reliance on the Service, and you
        further agree and acknowledge that your use of or reliance on the
        Service is made entirely at your own risk. You further acknowledge that
        it is your responsibility to comply with all applicable laws (including
        traffic laws) while using the Service.
      </p>
      <p>
        THE INFORMATION PROVIDED BY THE SERVICE IS NOT INTENDED TO REPLACE THE
        INFORMATION PRESENTED ON THE ROAD. IN THE EVENT THAT THE INFORMATION
        PRESENTED ON THE ROAD (TRAFFIC LIGHTS, TRAFFIC SIGNS, POLICE PERSON,
        ETC.) INSTRUCTS DIFFERENTLY THAN THE SERVICE, YOU MUST NOT RELY ON THE
        SERVICE.
      </p>
      <p>
        OPS exerts efforts to provide you with a high quality and satisfactory
        service. However, We do not warrant that the Service will operate in an
        uninterrupted or error-free manner, or that it will always be available
        or free from all harmful components, or that it is safe, secured from
        unauthorized access to OPS’s computers, immune from damages, free
        of malfunctions, bugs or failures, including, but not limited to
        hardware failures, Software failures and Software communication
        failures, originating either in OPS services or any of its providers.
      </p>
      <p>
        OPS, INCLUDING ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES,
        SUB-CONTRACTORS AND AGENTS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
        INCIDENTAL OR CONSEQUENTIAL DAMAGE, OR ANY OTHER DAMAGE, AND LOSS
        (INCLUDING LOSS OF PROFIT AND LOSS OF DATA), COSTS, EXPENSES AND
        PAYMENTS, EITHER IN TORT, CONTRACTUAL, OR IN ANY OTHER FORM OF
        LIABILITY, ARISING FROM, OR IN CONNECTION WITH THE USE OF, OR THE
        INABILITY TO USE THE SERVICE, OR FROM ANY FAILURE, ERROR, OR BREAKDOWN
        IN THE FUNCTION OF THE SERVICE, OR FROM ANY FAULT, OR ERROR MADE BY OUR
        STAFF OR ANYONE ACTING ON ITS BEHALF, OR FROM YOUR RELIANCE ON THE
        CONTENT OF THE SERVICE, INCLUDING, WITHOUT LIMITATION, CONTENT
        ORIGINATING FROM THIRD PARTIES, OR FROM ANY COMMUNICATION WITH THE
        SERVICE, OR WITH OTHER USERS ON OR THROUGH THE SERVICE, OR FROM ANY
        DENIAL OR CANCELLATION OF YOUR USER ACCOUNT, OR FROM RETENTION,
        DELETION, DISCLOSURE AND ANY OTHER USE OR LOSS OF YOUR CONTENT ON THE
        SERVICE. IN ANY EVENT, YOUR SOLE REMEDY WILL BE LIMITED TO CORRECTING
        SUCH ERRORS, OR MALFUNCTIONS, AND IN LIGHT OF THE RELEVANT
        CIRCUMSTANCES.
      </p>
      <p>
        Without derogating from any applicable law, you agree to indemnify and
        hold harmless OPS and its employees, officers, directors and agents, as
        well as all third party advertisers of Ads from and against all claims,
        damages, expenses, losses and liabilities that arise as a result of your
        violation of these Terms. In view of the fact that the Service is
        provided to you free-of-charge, this indemnity is intended to cover all
        expenses, payments, loss, loss of profits or any other damage, direct or
        indirect, monetary or non-monetary, incurred by OPS, its employees,
        officers, directors or agents as a result of your violation of the
        Terms, including but not limited to legal expenses and attorney fees.
      </p>
      
        <p><strong>7 Governing Law</strong></p>

      <p>
        To the maximum extent permitted by applicable law, by virtue of
        accessing and using the Services, you agree to indemnify, defend, and
        hold OPS, its subsidiaries, suppliers and other partners harmless from
        any claim, demand, and damages of any kind, including reasonable
        accounting and attorneys' fees, made by any third party due to or
        arising out of (a) any Data you access or share through the Services;
        (b) your use of the Services, (c) your connection to the Services, (d)
        your violation of these Terms, (e) any violation of the rights of any
        other person or entity by you, or (f) your employment of the Services to
        meet another user in person or to locate and attend any offline place or
        event. OPS reserves the right, at your sole cost and expense, to assume
        the exclusive defense and control of any matter for which you are
        required to indemnify OPS under the Terms, and you agree to cooperate
        with the defense of any such claims.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <br>
        <br>
        These Terms shall be governed by and construed in accordance with the
        laws of the State of Texas and controlling U.S. federal law as
        applicable, without regard to its conflict of law principles.
      </p>

        <p><strong>8 Arbitration.</strong></p>

      <p>
          ANY AND ALL DISPUTES OR CLAIMS ARISING OUT OF OR RELATING TO THESE
          TERMS SHALL BE RESOLVED BY BINDING ARBITRATION IN ACCORDANCE WITH THE
          PROVISIONS OF THIS SECTION AND MAY ONLY BE BROUGHT AND PROCEED IN YOUR
          INDIVIDUAL CAPACITY AND NOT AS A PURPORTED REPRESENTATIVE OF ANY CLASS
          OR AS A PRIVATE ATTORNEY GENERAL OR ON A COLLECTIVE BASIS. ARBITRATION
          DOES NOT INVOLVE A JUDGE OR JURY AND THE REVIEW OF MATTERS MAY BE
          LIMITED BUT AN ARBITRATOR MAY AWARD THE SAME DAMAGES AS A
          COURT.
      </p>
      <p>
          BY USING OR ACCESSING THE SERVICES, YOU UNDERSTAND AND AGREE THAT YOU
          ARE WAIVING, AND THEREBY LIMITING YOUR RIGHT TO OTHER AVAILABLE
          DISPUTE RESOLUTION PROCESSES SUCH AS A COURT ACTION TO SETTLE ANY
          DISPUTES OR CLAIMS AND INSTEAD YOU AGREE TO REFER YOUR DISPUTE TO
          ARBITRATION.
      </p>
      <p>
        All disputes, regardless of type, must be arbitrated, and arbitrations
        may only proceed individually and not as a class representative, private
        attorney general, or otherwise on a collective basis.
      </p>
      <p>
        You understand and agree not to initiate any action or arbitration
        proceeding for any claim unless and until you have provided OPS with
        specific written notice of each claim and a reasonable opportunity after
        such notice to cure any such claim.
      </p>
      <p>
        The forum for the arbitration shall be in a reasonably convenient
        county, as mutually agreed upon by the parties. The arbitration shall be
        administered by the American Arbitration Association ("AAA"), pursuant
        to its then-current Commercial Arbitration Rules (the "AAA Rules"), as
        modified by any other provisions that the parties may jointly agree upon
        in writing. There shall be a single arbitrator, mutually selected by the
        parties to the dispute, and if such parties are unable to agree upon an
        arbitrator, AAA shall designate an arbitrator (the "Arbitrator"). The
        Arbitrator shall be a retired judge or licensed attorney with at least
        twenty (20) years of experience in business and commercial law matters.
      </p>
      <p>
        If any party brings a claim in a court that is required by this Section
        to be brought in arbitration, and the other party successfully moves for
        an order or petition compelling arbitration, the non-prevailing party
        shall be obligated to pay the prevailing party's costs and attorney fees
        in connection with securing such order or petition.
      </p>
      <p>
        The Arbitrator shall hear and determine, in advance of the hearing on
        the merits, any dispositive or partially dispositive motions for summary
        adjudication or for dismissal and shall determine a date by which such
        motions must be filed. The Arbitrator's decision shall be final and
        binding on the parties hereto and may be entered in any court of
        competent jurisdiction. For the arbitration, discovery by each party
        shall be limited to reasonable requests for production of documents. No
        additional discovery (e.g., interrogatories, depositions or requests for
        admissions) shall be permitted except by mutual written consent or as
        ordered by the Arbitrator upon good cause shown.
      </p>
      <p>
        Except as may be expressly set forth herein or as otherwise mutually
        agreed, each party shall bear its own costs and attorney fees occurred
        in connection with any dispute. The fees and expenses of the Arbitrator
        shall be borne as determined by the Arbitrator, and in the absence of a
        determination shall be shared equally by the parties. You and OPS retain
        the right to apply to any court of competent jurisdiction for
        provisional relief such as preliminary injunctions or attachments.
      </p>
      
        <p><strong>9 Copyright Information</strong>.</p>
      
      <p>
        OPS claims no proprietary rights to, or affiliation with, any third
        party trademarks or logo references appearing in the Services or any OPS
        application. You should not infer any affiliation, sponsorship, or
        endorsement from the use of third party marks in the Services, as such
        marks are used solely to designate certain products or services as
        belonging to their respective owners.
      </p>
      <p>
        Images of people or places displayed in the Services are either the
        property of, or used with permission by, OPS. The use of these images by
        you, or anyone else authorized by you, is prohibited unless specifically
        permitted by these Terms or specific permission provided elsewhere in
        the Services. Any unauthorized use of the images may violate copyright
        laws, trademark laws, the laws of privacy and publicity, and
        communications regulations and statutes.
      </p>
      <p>
        OPS may, from time to time, provide links to other outside websites not
        associated with OPS. OPS has not reviewed and is not responsible for
        such linked websites or the content of any of the linked websites. OPS
        provides these links as an additional resource for users of the Services
        and makes no representations regarding the content of any linked website
        or any companies that own, control, or manage the linked websites.
        Consequently, OPS cannot be held responsible for the accuracy,
        relevancy, copyright compliance, legality, or decency of material
        contained in, programs used by, or services or products offered by,
        websites linked to from the Services.
      </p>
      <p>
        <strong>10.4. Digital Millennium Copyright Act (DMCA) Notice and
          Policy</strong>
      </p>
      <p><strong>10.4.1 Notifications</strong> </p>
      <p>
        We respect the intellectual property rights of others. If you believe
        that any material available on or through the Site infringes upon any
        copyright you own or control, please immediately notify our Designated
        Copyright Agent using the contact information provided below (a
        “Notification”). A copy of your Notification will be sent to
        the person who posted or stored the material addressed in the
        Notification. Please be advised that pursuant to federal law you may be
        held liable for damages if you make material misrepresentations in a
        Notification. Thus, if you are not sure that material located on or
        linked to by the Site infringes your copyright, you should consider
        first contacting an attorney.
      </p>
      <p>
        All Notifications should meet the requirements of DMCA 17 U.S.C. §
        512(c)(3) and include the following information: (1) A physical or
        electronic signature of a person authorized to act on behalf of the
        owner of an exclusive right that is allegedly infringed; (2)
        identification of the copyrighted work claimed to have been infringed,
        or, if multiple copyrighted works on the Site are covered by the
        Notification, a representative list of such works on the Site; (3)
        identification of the material that is claimed to be infringing or to be
        the subject of infringing activity and that is to be removed or access
        to which is to be disabled, and information reasonably sufficient to
        permit us to locate the material; (4) information reasonably sufficient
        to permit us to contact the complaining party, such as an address,
        telephone number, and, if available, an email address at which the
        complaining party may be contacted; (5) a statement that the complaining
        party has a good faith belief that use of the material in the manner
        complained of is not authorized by the copyright owner, its agent, or
        the law; and (6) a statement that the information in the notification is
        accurate, and under penalty of perjury, that the complaining party is
        authorized to act on behalf of the owner of an exclusive right that is
        allegedly infringed upon.
      </p>
      <p><strong>10.4.2 Counter Notification</strong> </p>
      <p>
        If you believe your own copyrighted material has been removed from the
        Site as a result of a mistake or misidentification, you may submit a
        written counter notification to [us/our Designated Copyright Agent]
        using the contact information provided below (a “Counter
        Notification”). To be an effective Counter Notification under the
        DMCA, your Counter Notification must include substantially the
        following: (1) identification of the material that has been removed or
        disabled and the location at which the material appeared before it was
        removed or disabled; (2) a statement that you consent to the
        jurisdiction of the Federal District Court in which your address is
        located, or if your address is outside the United States, for any
        judicial district in which we are located; (3) a statement that you will
        accept service of process from the party that filed the Notification or
        the party's agent; (4) your name, address, and telephone number; (5) a
        statement under penalty of perjury that you have a good faith belief
        that the material in question was removed or disabled as a result of a
        mistake or misidentification of the material to be removed or disabled;
        and (6) your physical or electronic signature.
      </p>
      <p>
        If you send us a valid, written Counter Notification meeting the
        requirements described above, we will restore your removed or disabled
        material, unless we first receive notice from the party filing the
        Notification informing us that such party has filed a court action to
        restrain you from engaging in infringing activity related to the
        material in question. Please note that if you materially misrepresent
        that the disabled or removed content was removed by mistake or
        misidentification, you may be liable for damages, including costs and
        attorney's fees. Filing a false Counter Notification constitutes
        perjury.
      </p>

      
        <p><strong>11 Miscellaneous</strong></p>
      
      <p>
        By virtue of accessing and using the Services, you agree that no joint
        venture, partnership, employment, or agency relationship exists between
        you and OPS as a result of the Terms or your use of the Services. The
        Terms and any Product-Specific Terms, if applicable, constitute the
        entire agreement between you and OPS with respect to your use of the
        Services.
      </p>
      <p>
        Visiting the Site, sending us emails, and completing online forms
        constitute electronic communications. You consent to receive electronic
        communications, and you agree that all agreements, notices, disclosures,
        and other communications we provide to you electronically, via email and
        on the Site, satisfy any legal requirement that such communication be in
        writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
        US OR VIA THE SITE. You hereby waive any rights or requirements under
        any statutes, regulations, rules, ordinances, or other laws in any
        jurisdiction which require an original signature or delivery or
        retention of non-electronic records, or to payments or the granting of
        credits by any means other than electronic means.
      </p>
      <p>
        If any provision of the Terms shall be deemed invalid, void, or for any
        reason unenforceable, that provision shall be deemed severable and shall
        not affect the validity and enforceability of any remaining provision.
      </p>
      <p>
        The failure of OPS to exercise or enforce any right or provision of the
        Terms does not constitute a waiver of such right or provision. If any
        provision of the Terms is found by a court of competent jurisdiction to
        be invalid, the parties nevertheless agree that the court should give
        effect to the parties' intentions as reflected in the provision, and the
        other provisions of the Terms shall be unaffected thereby and remain in
        full force and effect.
      </p>
      <p>
        Notice from OPS via email, regular mail, or notices or links displayed
        in connection with the Services constitutes acceptable notice under the
        Terms. OPS is not responsible for your failure to receive notice if
        email is quarantined by any email security system (e.g., "junk" or
        "spam" folder) or for the failure to provide an accurate or current
        email address. Notice will be considered received forty-eight hours
        after it is sent if transmitted via email or regular mail. In the event
        that notice is provided via links displayed in connection with the
        Services, then it will be considered received twenty-four hours after it
        is first displayed.
      </p>
     
        <p><strong>12 Contact Us</strong></p>
    
      <p>
        <br>
        If you have any feedback, questions or comments about the Services,
        please contact our Support Team by email, by phone at 1-877-873-8729.
      </p>
      <p>
        Notice to Overall Parts Solutions under this Agreement may be
        accomplished by sending e-mail to
        <a target="_blank" href="mailto:info@seeyamobile.com">info@seeyamobile.com</a>
        or by conventional mail to:
      </p>
      <p style="text-align: center; margin-bottom: 0">
        Overall Parts Solutions
      </p>
      <p style="text-align: center; margin-bottom: 0">
        1450 Post and Paddock Street
      </p>
      <p style="text-align: center; margin-bottom: 0">
        Grand Prairie, TX 75050
      </p>
      <p>
        <br>
        and include the subject as "Attn: Legal - Terms and Conditions of Use".
        Please be sure to include in any email or postal mail your full name,
        email address, postal address, and any message.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  </div>
</div>
