import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment as environmentConfig } from '@appFunctions/env.generated';

@Component({
  selector: 'app-minisite-layout',
  templateUrl: './minisite-layout.component.html',
  styleUrls: ['./minisite-layout.component.scss']
})
export class MinisiteLayoutComponent implements OnInit {

  toogleMobileMenu = false;
  businessLogin = environmentConfig.siteOrigin;

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      // window.scrollTo(0, 0);
      this.toogleMobileMenu = false;
  });
  }

  toogleMenu() {
    this.toogleMobileMenu = true;
  }

}
