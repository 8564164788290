import { Component, OnInit } from '@angular/core';
import { filter} from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { environment as environmentConfig } from '@appFunctions/env.generated';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  isLanding: any;
  toogleMobileMenu = false;
  businessLogin = environmentConfig.siteOrigin;

  constructor(private router: Router) { }

  ngOnInit() {
    console.log(this.router.url);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(res => {
      if (this.router.url === '/landing' || this.router.url === '/404error') {
        this.isLanding = true;
      } else {
        this.isLanding = false;
      }
    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      // window.scrollTo(0, 0);
      this.toogleMobileMenu = false;
  });
  }

  toogleMenu() {
    this.toogleMobileMenu = true;
  }


}
