import {Component} from '@angular/core';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent {

  task = {
    address: 'Dallas, TX 75201, USA',
    latitude: 32.7766642,
    isBusiness: true,
    imageUrl: '/assets/img/mobile-layout/palceholder-pic@2x.png',
    name: 'mcdonalds ',
    longitude: -96.79698789999999
  };

  showPicture() {
    switch (this.task.isBusiness) {
      case true:
        if (this.task.imageUrl !== '') {
          return this.task.imageUrl;
        } else {
          return '/assets/img/mobile-layout/business-placeholder.png';
        }
      case false:
        return '/assets/img/mobile-layout/residential-placeholder.png';
      default:
        break;
    }
  }
}
