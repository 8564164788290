<section class="footer">
  <div class="svg-wrapper-start">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
      preserveAspectRatio="none"
    >
      <path
        d="M0,192L48,176C96,160,192,128,288,138.7C384,149,480,203,576,218.7C672,235,768,213,864,192C960,171,1056,149,1152,144C1248,139,1344,149,1392,154.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
      ></path>
    </svg>
  </div>
  <div class="content sides-padding">
    <div class="col1">
      <img src="/assets/img/logo.svg" alt="seeya landing">
      <p>The ultimate curb side experience! Reduce wait time by engaging in controlled and safe, visitor management processes.</p>
      <!-- <button class="arrow-btn">
        <a class="arrow text-bold">View a Demo</a>
      </button> -->
    </div>
    <div class="col2">
     
    </div>
    <div class="col3">
      <p><strong>Links</strong></p>
      <ul>
        <li><a routerLink="/" routerLinkActive="active">Home</a></li>
        <li><a routerLink="/careers" routerLinkActive="active">Careers</a></li>
        <li><a routerLink="/contactus" routerLinkActive="active">Contact Us</a></li>
      </ul>
    </div>
    <div class="col4">
      <p><strong>Contact Us</strong></p>
      <p>
        Explore the future with us. Feel free to get in touch.
      </p>
      <a target="_blank" href="mailto:info@seeyamobile.com?subject=Information%20%7C%20SeeYa%20Portal">info@seeyamobile.com</a>
    </div>
  </div>
  <div class="end sides-padding">
    <div class="container-end">
      <div>
        <p>©SeeYa! Mobile 2021, All Rights Reserved</p>
      </div>
      <div class="social-media">
        <ul>
          <li>
            <a routerLink="/policy" routerLinkActive="active">Privacy Policy&nbsp; | </a>
          </li>
          <li>
            <a routerLink="/terms" routerLinkActive="active">Terms of Use</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
