import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LandingComponent} from './features/landing/landing.component';
import {ContactusComponent} from './features/contactus/contactus.component';
import {PolicyComponent} from './features/policy/policy.component';
import {TermsOfUseComponent} from './features/terms-of-use/terms-of-use.component';
import {PagenotfoundComponent} from './layout/pagenotfound/pagenotfound.component';
import {SupportComponent} from './features/support/support.component';
import {PortalLayoutComponent} from './layout/portal-layout/portal-layout.component';
import {MobileLayoutComponent} from './layout/mobile-layout/mobile-layout.component';
import {MinisiteLayoutComponent} from './layout/minisite-layout/minisite-layout.component';
import {TaskComponent} from './features/mobile/task/task.component';
import {MiniSiteComponent} from './features/mini-site/mini-site.component';
import {CareersComponent} from './features/careers/careers.component';
import {CareersItemComponent} from './features/careers/careers-item/careers-item.component';
import {NewsFeedComponent} from './features/news-feed/news-feed.component';
import {NewsComponent} from './features/news-feed/news/news.component';
import {EulaComponent} from './features/eula/eula.component';

const routes: Routes = [
  {
    path: '',
    component: MinisiteLayoutComponent,
    children: [
      {path: '', component: MiniSiteComponent}
    ]
  },
  {
    path: '',
    component: PortalLayoutComponent,
    children: [
      {path: 'landing', component: LandingComponent},
      {path: 'contactus', component: ContactusComponent},
      {path: 'policy', component: PolicyComponent},
      {path: 'terms', component: TermsOfUseComponent},
      {path: 'eula', component: EulaComponent},
      {path: 'support', component: SupportComponent},
      {path: 'careers', component: CareersComponent},
      {
        path: 'news-and-media',
        component: NewsFeedComponent
      },
      {
        path: 'news-and-media/:newsName', component: NewsComponent
      },
      {
        path: 'careers/:careerName', component: CareersItemComponent
      }
    ]
  },
  {
    path: '',
    component: MobileLayoutComponent,
    children: [
      {path: 'task/:id', component: TaskComponent},
    ]
  },
  {path: '404error', component: PagenotfoundComponent},
  {path: '**', redirectTo: '/404error'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
