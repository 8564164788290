<app-header [sectionTitle]="sectionTitle"></app-header>
<div class="container-grid contactus sides-padding">
  <div class="col2">
    <div class="titles">
      <h1>Talented and out-of-the-box thinkers wanted!</h1>
      <h4>CURRENT OPPORTUNITIES</h4>
      <div class="line"></div>
    </div>
    <div class="table-container table-responsive-md">

          <table class="table table-hover">
            <thead>
              <tr>
                <th>Job Title</th>
                <th>Description</th>
                <th>Requirements</th>
                <th width="10%">Select</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let career of careers">
                <td><a [routerLink]="['/careers' , career.path]">{{career.position}}</a></td>
                <td>{{career.descriptionShort}}</td>
                <td>{{career.requirementsShort}}</td>
                <td><button class="select-btn" ><a (click)="onApply(career.position)">e-mail</a></button></td>
              </tr>
            </tbody>
          </table>

      <!-- <table class="table table-hover">
        <thead>
          <tr>
            <th>Job Title</th>
            <th>Description</th>
            <th>Requirements</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>React Native 
              Developer</td>
            <td>React Native 
              Developer</td>
            <td>Overall Parts Solutions is seeking exceptional React 
              Native developers to join our family and work on 
              the world’s most cutting-edge platform in the 
              automotive industry.</td>
            <td><button class="select-btn"><a target="_blank" href="mailto:info@seeyamobile.com?subject=SeeYa%20Portal%20%7C%20Careers">Email</a></button></td>
          </tr>
        </tbody>
      </table>   -->
    </div>
  </div>
</div>
