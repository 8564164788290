<div class="grid-container">
  <div class="navigation">
    <app-navigation></app-navigation>
  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>
