import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LandingComponent} from './features/landing/landing.component';
import {ContactusComponent} from './features/contactus/contactus.component';
import {PolicyComponent} from './features/policy/policy.component';
import {EulaComponent} from './features/eula/eula.component';
import {TermsOfUseComponent} from './features/terms-of-use/terms-of-use.component';
import {FooterComponent} from './layout/portal-layout/footer/footer.component';
import {NavigationComponent} from './layout/portal-layout/navigation/navigation.component';
import {PagenotfoundComponent} from './layout/pagenotfound/pagenotfound.component';
import {HeaderComponent} from './layout/portal-layout/header/header.component';
import {SupportComponent} from './features/support/support.component';
import {PortalLayoutComponent} from './layout/portal-layout/portal-layout.component';
import {MobileLayoutComponent} from './layout/mobile-layout/mobile-layout.component';
import {MinisiteLayoutComponent} from './layout/minisite-layout/minisite-layout.component';
import {TaskComponent} from './features/mobile/task/task.component';
import {HeaderMobileComponent} from './layout/mobile-layout/header-mobile/header-mobile.component';
import {CareersComponent} from './features/careers/careers.component';
import {CareersItemComponent} from './features/careers/careers-item/careers-item.component';
import {NewsService} from './shared/services/news.service';
import {CareersService} from './shared/services/careers.service';
import {NewsFeedComponent} from './features/news-feed/news-feed.component';
import {NewsComponent} from './features/news-feed/news/news.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    ContactusComponent,
    PolicyComponent,
    TermsOfUseComponent,
    FooterComponent,
    NavigationComponent,
    PagenotfoundComponent,
    HeaderComponent,
    SupportComponent,
    PortalLayoutComponent,
    MobileLayoutComponent,
    TaskComponent,
    HeaderMobileComponent,
    MinisiteLayoutComponent,
    CareersComponent,
    CareersItemComponent,
    NewsFeedComponent,
    NewsComponent,
    EulaComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    RouterModule
  ],
  providers: [
    NewsService,
    CareersService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
